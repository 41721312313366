import { z } from "zod";
// validation
import { PaymentProcessor, ZCardProcessorName } from "@/enums/payment";
import { UsaStateCode } from "@/general/regions";
import { ZMpd } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/paymentSchemas";

/** ### SavedPmtMethodRes: Response type for `paymentService.getSavedPaymentMethods()`
 * Endpoint: `/Payment/SavedPaymentMethods`
 * @todo get data types of props with `any` type
 */
export const SavedPmtMethodRes = z.object({
  /** @deprecated @todo add enum - observed: ["Credit"] */
  acctType: z.any().nullish(),
  /** @deprecated @todo add enum */
  achAcctType: z.any().nullish(),
  address: z.string().nullish(),
  appRecId: z.number().nullish(),
  canBeDeleted: z.boolean().nullish(),
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'paymentProcessor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  cardProcessor: PaymentProcessor.nullish(),
  /** @note Same as ZMpd.brand - Visa | Mastercard | etc. */
  cardType: ZMpd.shape.brand,
  city: z.string().nullish(),
  compId: z.number().nullish(),
  convFeeRecurring: z.number().nullish(),
  /** @deprecated @todo remove on backend. This is derived from fields `${fName} ${(mName?)} ${lName}` */
  custName: z.string().nullish(),
  email: z.string().nullish(),
  fName: z.string().nullish(),
  isActive: z.boolean().nullish(),
  lName: z.string().nullish(),
  /** @note Same as ZMpd.lastFour - Visa | Mastercard | etc. */
  last4: ZMpd.shape.lastFour,
  locId: z.number().nullish(),
  mName: z.string().nullish(),
  /** @note Number represented as str; @note may be nullable */
  mpdId: z.string().nullish(),
  /** @deprecated @todo add enum - observed: ["CreditCard", ] */
  mpdType: z.any(),
  orgId: z.number().nullish(),
  phone: z.string().nullish(),
  recId: z.number().nullish(),
  /** @deprecated @todo add enum - observed: ["NotSetup",] */
  recurringStatus: z.number().nullish(),
  /** Nullable @todo add enum */
  recurringStatusPending: z.any().nullish(),
  state: UsaStateCode.nullish(),
  zip: z.string().nullish(),
});
export type SavedPmtMethodRes = z.infer<typeof SavedPmtMethodRes>;

export const SavedPmtMethod = SavedPmtMethodRes.omit({ cardProcessor: true }).extend({
  cardProcessor: z.preprocess(
    (input) =>
      (input as string).toUpperCase() === ZCardProcessorName.enum.REPAY
        ? ZCardProcessorName.enum.REPAY
        : input,
    ZCardProcessorName
  ),
});
export type SavedPmtMethod = z.infer<typeof SavedPmtMethod>;

// @todo Uncomment after figuring out all 'failure' values
// export const PmtStatusField = z.enum(["Done-Posted", "Approved", "New", ...]);
// export type PmtStatusField = z.infer<typeof PmtStatusField>;

/** @todo replace with `export const PmtStatusFieldSuccess = PmtStatusField.extract(["Done-Posted", "Approved", "New"])` */
export const PmtStatusFieldSuccess = z.enum(["Done-Posted", "Approved", "New"]);
export type PmtStatusFieldSuccess = z.infer<typeof PmtStatusFieldSuccess>;

export interface PaymentStatusResponse {
  paymentLogRecId: number;
  paymentRecId: number;
  /** @todo use `PmtStatusField` - see comment above */
  paymentStatus: string;
}
