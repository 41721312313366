import { FC, PropsWithChildren, ReactNode } from "react";
import { RxCross2 } from "react-icons/rx";
// mui
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";
import Modal, { ModalProps } from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

/** ### Wrapper for Modal-implementation using commonly-used style and easier use
 * Modal is a lower-level construct that is leveraged by the following components:
 *
 * *   [Dialog](https://mui.com/material-ui/api/dialog/)
 * *   [Drawer](https://mui.com/material-ui/api/drawer/)
 * *   [Menu](https://mui.com/material-ui/api/menu/)
 * *   [Popover](https://mui.com/material-ui/api/popover/)
 *
 * If you are creating a modal dialog, you probably want to use the [Dialog](https://mui.com/material-ui/api/dialog/) component
 * rather than directly using Modal.
 *
 * This component shares many concepts with [react-overlays](https://react-bootstrap.github.io/react-overlays/#modals).
 *
 * Demos:
 *
 * - [Modal](https://mui.com/material-ui/react-modal/)
 *
 * API:
 *
 * - [Modal API](https://mui.com/material-ui/api/modal/)
 */
const CommonModal: FC<
  PropsWithChildren &
    Omit<ModalProps, "children"> & {
      containerProps?: Grid2Props;
      /** @todo implement title component */
      title?: ReactNode;
      isKendo?: boolean;
    }
> = ({ containerProps, title, isKendo = true, sx: sxProps, children, ...props }) => (
  <Modal {...props} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Grid
      container
      direction="column"
      component={Paper}
      maxWidth="80%"
      minWidth={isKendo ? "600px" : "800px"}
      maxHeight="95%"
      borderRadius={isKendo ? "20px" : undefined}
      wrap="nowrap"
      overflow="hidden"
      p={2}
      gap={5}
      sx={{
        transform: "translate(-50%,-50%)",
        top: "50%",
        left: "50%",
        position: "absolute",
        ...sxProps,
      }}
      {...containerProps}
    >
      <IconButton
        sx={{ position: "absolute", top: 0, right: 0, m: 2 }}
        onClick={props.onClose as any}
      >
        <RxCross2 />
      </IconButton>
      {children}
    </Grid>
  </Modal>
);

export default CommonModal;
