import { SalesSubviewKey } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  SalesQuotesFilterKey,
  SalesQuotesSortKey,
  salesQuotesFilterFields,
  salesQuotesSortFields,
} from './dataModelConfig';
import { SalesQuotesDb } from './interfaces';
import { SalesQuotesTableColumns } from './tableColumns';

export const salesQuotesSubviewConfig: SubviewInfo<SalesQuotesSortKey, SalesQuotesFilterKey> = {
  text: 'Deals & Quotes',
  route: SalesSubviewKey.dealsQuotes,
  columns: SalesQuotesTableColumns,
  dateFilterField: { field: 'quoteDate', label: 'Date of Quote' },
  initSortField: 'quoteDate',
  sortableFields: salesQuotesSortFields,
  filterableFields: salesQuotesFilterFields,
  dbRowValidationFxn: (row: SalesQuotesDb) => SalesQuotesDb.new(row),
};
