export const DCS_BLUE = "#294695";
export const LINK_BLUE = "#294695";
export const COLOR_DANGER = "#c60000";
export const COLOR_SUCCESS = "#129a38";
export const TEXT_PRIMARY1 = "#111827"; // $textPrimary1
export const BG_HIGHLIGHT = "#eff0f4"; // $backgroundHighlight: #eff0f4;
export const HOVER_TRANSITION_SPEED = "0.15s"; // $hoverTransitionSpeed: 0.15s;
export const COLOR_INACTIVE = "#878787";
export const BLUE_LIGHT = "#29469533"; // $blueLight: #29469533;
export const BORDER_GREY = "rgb(204, 204, 204)"; // $borderGrey
