import { FC } from "react";
// mui
// kendo
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
// state
// utils
import SectionBody from "@/mui/components/form/Section/SectionBody";
import Section from "@/mui/components/form/Section";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { BackLink } from "@/components/backLink/BackLink";
import SectionColumn from "@/mui/components/form/Section/SectionColumn";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { TextInput } from "@/components";
import MuiKendoButton from "@/mui/components/buttons/MuiKendoButton";
import { useSidenoteCtx } from "./SidenoteProvider";
import { PrintButton } from "./PrintButton";

const textStyle = { marginTop: "1px", marginBottom: "1px" };

/** ###  */
const SidenoteForm: FC = () => {
  const activeSidenote = useSidenoteCtx((s) => s.activeSidenote);
  const formState = useSidenoteCtx((s) => s.formState);
  const { isValid, isDirty } = formState;
  const { isValid: isReqValid } = useSidenoteCtx((s) => s.reqBody);
  const isNew = useSidenoteCtx((s) => s.isNew);
  const plan1 = useSidenoteCtx((s) => s.plan1);
  const plan2 = useSidenoteCtx((s) => s.plan2);
  const handleSubmit = useSidenoteCtx((s) => s.handleSubmit);
  const handleCancel = useSidenoteCtx((s) => s.handleCancel);
  const handleDelete = useSidenoteCtx((s) => s.handleDelete);

  return (
    <AccountsMainPanel navBarTitle="Sidenotes" loading={false}>
      <Section expanded={true}>
        <Grid2
          container
          direction="row"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Grid2>
            <BackLink to={"../"} title="Back to list" />
          </Grid2>
          <Grid2>
            {activeSidenote?.appRecId && <PrintButton isDisabled={isNew} />}
          </Grid2>
        </Grid2>
        <SectionBody
          sx={{ py: 0 }}
          leftColElems={<LeftContainer />}
          rightColElems={<RightContainer />}
        />
        <SectionBody>
          <SectionColumn>
            <Grid2
              sx={{
                padding: "8px 0 8px 0",
                color: "#111827",
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Repayment Details
            </Grid2>
            <TextInput value={plan1} style={textStyle} required defaultValue="" disabled />
            <TextInput value={plan2} style={textStyle} required defaultValue="" disabled />
          </SectionColumn>

          <Grid2 container direction="row" flex={1} justifyContent="flex-end" gap={1}>
            {isNew ? (
              <>
                <MuiKendoButton
                  onClick={handleSubmit}
                  disabled={!isDirty || !isValid || !isReqValid}
                >
                  Submit
                </MuiKendoButton>
                <MuiKendoButton onClick={handleCancel}>Cancel</MuiKendoButton>
              </>
            ) : (
              <MuiKendoButton onClick={handleDelete}>Delete</MuiKendoButton>
            )}
          </Grid2>
        </SectionBody>
      </Section>
    </AccountsMainPanel>
  );
};

export default SidenoteForm;
