import { FC, FocusEventHandler, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
// mui
import { Grid2Props } from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker/DatePicker.types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { FormControlProps } from "@mui/material/FormControl";
import { FormLabelProps } from "@mui/material/FormLabel";
// local
import MuiKendoFormControl from "./MuiKendoFormControl";

// @todo add separate component MuiKendoDatePickerForm - which is wrapped in a <FormControl> element
/** Wrapper for MUI's Date-Picker
 * Demos:
 *
 * - [DatePicker](https://mui.com/x/react-date-pickers/date-picker/)
 * - [Validation](https://mui.com/x/react-date-pickers/validation/)
 *
 * API:
 *
 * - [DatePicker API](https://mui.com/x/api/date-pickers/date-picker/)
 */
const MuiKendoDateField: FC<
  Omit<DatePickerProps<Dayjs>, "value"> & {
    id?: string;
    name?: string;
    value?: Dayjs | null | undefined | string;
    required?: boolean;
    placeholder?: string;
    errors?: string | boolean;
    onBlur?: FocusEventHandler<HTMLDivElement> | undefined;
    textFieldSx?: SxProps;
    containerProps?: Grid2Props;
    formControlProps?: FormControlProps;
    labelProps?: FormLabelProps;
  }
> = ({
  id,
  name,
  value: inputValue,
  required = false,
  /** @deprecated remove once all fields are removed from non-MUI components */
  placeholder,
  onBlur,
  containerProps,
  formControlProps,
  labelProps,
  label,
  ...inputProps
}) => {
  /** @deprecated Remove after Alpha is sunsetted. */
  const editorId = useMemo(() => (name ? name : crypto.randomUUID()), [name]);

  /** @deprecated Transition off of using strings as date-field values. values passed in should be of type `Dayjs`. */
  const value = typeof inputValue === "string" ? dayjs(inputValue) : inputValue;

  return (
    <MuiKendoFormControl
      {...formControlProps}
      disabled={inputProps.disabled}
      containerProps={containerProps}
      label={required ? `${label} *` : label}
      onBlur={onBlur}
      labelProps={{
        ...labelProps,
        sx: { px: 0, flex: 1, fontWeight: required ? 800 : 700, ...labelProps?.sx },
      }}
      error={!!inputProps.errors}
    >
      <LocalizationProvider>
        <DatePicker
          disabled={inputProps.disabled}
          minDate={dayjs("2000-01-01")}
          {...inputProps}
          value={value}
          onChange={(value, context) => {
            if (!inputProps.onChange) return;

            // @note look at the input value type to determine what to set in the on-change
            const appliedValue = typeof inputValue === "string" ? dayjs(value) : value;

            inputProps.onChange(appliedValue, context);
          }}
          // @note these style changes are for matching the kendo/orig input styles. remove once possible.
          slotProps={{
            ...inputProps.slotProps,
            textField: {
              // possibly needed for non-standalone, unnecessary for standalone
              id: editorId,
              margin: "none",
              label: "mm/dd/yyyy",
              fullWidth: true,
              variant: "outlined",
              error: !!inputProps.errors || undefined,
              sx: { ...inputProps.textFieldSx, flex: 1.25, fontSize: 14 },
              InputLabelProps: {
                shrink: false,
                sx: {
                  ml: -0.5,
                  fontSize: 15,
                  "&.MuiFormLabel-filled, .Mui-focused": { display: "none" },
                },
              },
              InputProps: {
                slotProps: {
                  root: {
                    sx: { fontSize: 14, background: "rgb(255,255,255)", height: 36, my: "1px" },
                  },
                  input: {
                    sx: {
                      borderColor: "rgba(0, 0, 0, 0.16)",
                      "&.MuiOutlinedInput-notchedOutline": { borderColor: "rgba(0, 0, 0, 0.16)" },
                      "& .MuiOutlinedInput-input": {
                        "&.Mui-disabled": {
                          backgroundColor: "rgb(220 220 220)",
                          borderColor: "rgba(0, 0, 0, 0)",
                        },
                      },
                    },
                  },
                },
              },
              ...inputProps.slotProps?.textField,
            },
          }}
          sx={{ ...inputProps.sx }}
        />
      </LocalizationProvider>
    </MuiKendoFormControl>
  );
};

export default MuiKendoDateField;
