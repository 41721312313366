import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { isEmpty } from "lodash-es";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// utils
import { accountsService } from "@/services/accountsService";
import { inventoryService } from "@/services/inventoryService";
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useValidatedForm from "@/utils/forms/useValidatedForm";
import validateReqBody from "@/utils/forms/validateReqBody";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { planLong, useSNReCalc } from "./utils";
// interfaces
import { Nullish } from "@/interfaces/utilityTypes";
import { VendorListItem } from "@/interfaces/Vendors";
import { SidenoteAddOns, SidenoteDetail } from "@/interfaces/Accounts";
import { SidenoteForm, SidenoteReq } from "./forms";

const useCtxState = () => {
  const navigate = useNavigate();
  const appRecId = useAccountSelector((s) =>
    getRouteParamNum(s.accountInformation?.appRecId?.toString())
  );
  const stockNum = useAccountSelector((s) => s.accountInformation?.stockNum);
  const compId = useAccountSelector((s) =>
    getRouteParamNum(s.accountInformation?.compId?.toString())
  );
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const sidenoteRecIdParam = useMatch({
    path: "/accounts/:colRecId/sidenotes/:sidenoteRecId",
  })?.params;
  const sidenoteRecId = getRouteParamNum(sidenoteRecIdParam?.sidenoteRecId);
  const colRecId = getRouteParamNum(sidenoteRecIdParam?.colRecId);

  const [sidenoteRes, setSidenoteRes] = useState<SidenoteDetail[]>([]);
  const [sidenoteAddOns, setSidenoteAddOns] = useState<SidenoteAddOns | null>(null);
  const [sidenoteTypeOptions, setSidenoteTypeOptions] = useState<string[]>([]);
  const [vendorsList, setVendorsList] = useState<VendorListItem[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [plan1, setPlan1] = useState("");
  const [plan2, setPlan2] = useState("");
  const originalActiveSidenote = sidenoteRes.find((r) => sidenoteRecId === r.recId) || null;
  const nextDueDateActive = originalActiveSidenote?.nextDueDate;
  const pmtDueActive = originalActiveSidenote?.pmtDue;
  const activeSidenote = pmtDueActive?.isAfter(nextDueDateActive)
    ? { ...originalActiveSidenote, nextDueDate: pmtDueActive }
    : originalActiveSidenote;

  const isNew = sidenoteRecIdParam?.sidenoteRecId === "new";
  const snCount =
    sidenoteRes.length > 0 ? `S${sidenoteRes.length + 1}-${stockNum}` : `SN-${stockNum}`;

  const newSidenote = {
    addLab1: sidenoteAddOns?.add1,
    addLab2: sidenoteAddOns?.add2,
    addLab3: sidenoteAddOns?.add3,
    addLab4: sidenoteAddOns?.add4,
    addLab5: sidenoteAddOns?.add5,
    addLab6: sidenoteAddOns?.add6,
    addLab7: sidenoteAddOns?.add7,
    addLab8: sidenoteAddOns?.add8,
    addLab9: sidenoteAddOns?.add9,
    addLab10: sidenoteAddOns?.add10,
    addOn1: sidenoteAddOns?.price1,
    addOn2: sidenoteAddOns?.price2,
    addOn3: sidenoteAddOns?.price3,
    addOn4: sidenoteAddOns?.price4,
    addOn5: sidenoteAddOns?.price5,
    addOn6: sidenoteAddOns?.price6,
    addOn7: sidenoteAddOns?.price7,
    addOn8: sidenoteAddOns?.price8,
    addOn9: sidenoteAddOns?.price9,
    addOn10: sidenoteAddOns?.price10,
    intRate: sidenoteAddOns?.snIntRate,
    pmtAmt: 100,
    pmtSched: "Weekly",
    snNumber: snCount,
    snType: "",
  };
  const appliedSidenote: Nullish<SidenoteDetail> | null = isNew ? newSidenote : activeSidenote;

  const formState = useValidatedForm(
    SidenoteForm,
    {
      ...appliedSidenote,
      snTotal: activeSidenote?.snTotal ?? 0,
      snType: activeSidenote?.snType ?? "",
      snAmtFin: activeSidenote?.snAmtFin ?? 0,
      numPmts: activeSidenote?.numPmts ?? 0,
      pmtAmt: activeSidenote?.pmtAmt ?? 100,
      finalAmt: activeSidenote?.finalAmt ?? 0,
      finalDate: activeSidenote?.finalDate,
      pmtSched: activeSidenote?.pmtSched ?? "Weekly",
    }
  );
  const reqBody = validateReqBody(SidenoteReq, {
    ...formState.form,
    addLab1: sidenoteAddOns?.add1,
    addLab2: sidenoteAddOns?.add2,
    addLab3: sidenoteAddOns?.add3,
    addLab4: sidenoteAddOns?.add4,
    addLab5: sidenoteAddOns?.add5,
    addLab6: sidenoteAddOns?.add6,
    addLab7: sidenoteAddOns?.add7,
    addLab8: sidenoteAddOns?.add8,
    addLab9: sidenoteAddOns?.add9,
    addLab10: sidenoteAddOns?.add10,
    pmtDue: formState.form?.nextDueDate,
    colRecId: colRecId,
    compId: compId,
    acctAppRecId: getRouteParamNum(accountInformation?.appRecId),
  });

  const handleSubmit = async () => {
    if (!reqBody.reqBody) {
      console.warn("Invalid payload:", { reqBody: reqBody.reqBody });
      return;
    }
    try {
      const snRecId = await accountsService.postSidenote(reqBody.reqBody);
      if (typeof snRecId === "number") {
        loadSidenoteAddOns();
        loadSidenoteTypeOptions();
        await loadSidenoteHistory();
        navigate(`${snRecId}`);
      } else {
        console.error("Error posting sidenote", snRecId);
      }
    } catch {}
  };

  const handleCancel = async () => {
    if (!compId) return;
    try {
      formState.resetToDefault();
    } catch {}
  };

  const handleDelete = async () => {
    if (!compId) return;
    if (!activeSidenote?.appRecId) return;
    try {
      const vAppRecId = activeSidenote.appRecId;
      const snRecId = await accountsService.deleteSidenote(vAppRecId);
      if (snRecId) {
        loadSidenoteAddOns();
        loadSidenoteTypeOptions();
        await loadSidenoteHistory();
        navigate("./");
      } else {
        console.error("Error deleting sidenote", vAppRecId);
      }
    } catch {}
  };

  const loadSidenoteHistory = async () => {
    if (!appRecId) return;
    try {
      const res = await accountsService.getSidenotes(appRecId);
      const sidenoteList = res?.map((v) => {
        return {
          ...v,
          pmtDue: dayjs(v.pmtDue),
          snDate: dayjs(v.snDate),
          finalDate: dayjs(v.finalDate),
          nextDueDate: dayjs(v.nextDueDate),
        };
      });
      setSidenoteRes(sidenoteList ?? []);
    } catch (_) {}
  };

  const getVendorsList = async () => {
    try {
      const res = await inventoryService.getVendors();
      setVendorsList(res ?? []);
    } catch {}
  };

  const loadSidenoteAddOns = async () => {
    if (!compId) return;
    try {
      const res = await accountsService.getSidenoteAddOns(compId);
      setSidenoteAddOns(res ?? {});
    } catch (_) {}
  };

  const loadSidenoteTypeOptions = async () => {
    if (!compId) return;
    try {
      const res = await accountsService.getSidenoteTypeOptions(compId);
      setSidenoteTypeOptions(res ?? []);
    } catch (_) {}
  };

  const addOn1 = formState.form.addOn1;
  const addOn2 = formState.form.addOn2;
  const addOn3 = formState.form.addOn3;
  const addOn4 = formState.form.addOn4;
  const addOn5 = formState.form.addOn5;
  const addOn6 = formState.form.addOn6;
  const addOn7 = formState.form.addOn7;
  const addOn8 = formState.form.addOn8;
  const addOn9 = formState.form.addOn9;
  const addOn10 = formState.form.addOn10;
  const snDown = formState.form.snDown;
  const intRate = formState.form.intRate;
  const pmtDue = formState.form.pmtDue;
  const pmtSched = formState.form.pmtSched;
  const pmtAmt = formState.form.pmtAmt;

  useEffect(() => {
    if (isEmpty(formState.form)) return;

    if (isNew) {
      // @ts-ignore
      const calcValues = useSNReCalc(formState.form);
      formState.setField("nextDueDate", dayjs(calcValues.firstpmtdue));
      formState.setField("snTotal", calcValues.tsp);
      formState.setField("numPmts", calcValues.numpmts);
      formState.setField("pmtAmt", calcValues.pmtamt);
      formState.setField("snFinChg", calcValues.finchg);
      formState.setField("finalDate", calcValues.finalduedate);
      formState.setField("finalAmt", calcValues.final);
      formState.setField("snAmtFin", calcValues.amountfinanced);
      setPlan1(calcValues.plan1);
      setPlan2(calcValues.plan2);
    } else {
      if (isEmpty(formState.form) || !formState.form.pmtDue) return;
      if (isEmpty(formState.form)) return;
      setPlan1(
        planLong(
          formState.form.numPmts,
          formState.form.pmtAmt,
          formState.form.pmtDue?.format("YYYY-MM-DD"),
          formState.form.pmtSched,
          "Regular:"
        )
      );
      if (formState.form.finalAmt > 0) {
        setPlan2(
          planLong(
            1,
            formState.form.finalAmt,
            dayjs(formState.form.finalDate)?.format("YYYY-MM-DD"),
            "Final",
            "Final:"
          )
        );
      } else {
        setPlan2("");
      }
    }
  }, [
    addOn1,
    addOn2,
    addOn3,
    addOn4,
    addOn5,
    addOn6,
    addOn7,
    addOn8,
    addOn9,
    addOn10,
    snDown,
    intRate,
    pmtDue,
    pmtSched,
    pmtAmt,
  ]);

  useEffect(() => {
    loadSidenoteAddOns();
    loadSidenoteTypeOptions();
    getVendorsList();
    loadSidenoteHistory();
  }, [appRecId]);

  return {
    sidenoteRes,
    showForm,
    setShowForm,
    activeSidenote,
    isNew,
    formState,
    reqBody,
    /** @deprecated */
    appliedSidenote,
    plan1,
    setPlan1,
    plan2,
    setPlan2,
    snCount,
    sidenoteTypeOptions,
    setSidenoteTypeOptions,
    handleSubmit,
    handleCancel,
    handleDelete,
    vendorsList,
  };
};

export type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);
export const useSidenoteCtx = useCtxFactory(Ctx);
const SidenoteProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);

export default SidenoteProvider;
