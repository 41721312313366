import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
// interfaces
import { AxiosService } from './axiosService';
import { systemService } from './systemService';
import {
  DownPayment,
  CreditReport,
  CreditReportPayload,
  CreditReportResponse,
  CreditReportSettings,
  CashTerms,
  FinanceTerms,
  LeaseTerms,
  Lienholder,
  postNewSalePayload,
  PrintSalesDoc,
  QuoteData,
  SaleBuyer,
  SaleData,
  SaleManagement,
  SalesDocListData,
  ScannedDocument,
  ScannedDocumentUpload,
  SendSalesDocsToSecureClose,
  SendSalesDocToCryptoSign,
  TradeIn,
  UpdateAccountFromCreditReport,
  UpdateLandlord,
  UpdateVerification,
  VerificationItem,
  VerificationList,
  DeclineApplication,
  Declined,
  LeaseSaleCalc,
  LeaseSaleCalcResults,
  CustomerTaxInfo,
  UpdateCobuyer,
  LegalQuestions,
  FinanceTaxRates,
  IncomeAssessment,
  DclUnderwriting,
  InterviewQuestions,
  PostSale,
  SalesScoring,
  SalesScoringGridDto,
  ApplicationScoring,
  SecureClosePresentation,
  CryptoSignSignature,
  LhPhSubmissionModel,
  DealData,
} from '@/interfaces/Sales';
import { ApiResponse, GridData } from '@/interfaces/Api';
import {
  Employer,
  PreviousAddress,
  PreviousAddressOptional,
  Reference,
} from '@/interfaces/Accounts';
import { PfsSubviewReqDto } from '@/interfaces/requests';
import { ISalesSubviewDb } from '@/features/Sales/interfaces';
import { ApplicationDb } from '@/features/Sales/interfaces/application';
import {
  IWsFormAddlDataRes,
  IWsFormRes,
} from '@/features/Sales/detailView/WholesalesForm/interfaces';
import { WsFormReq } from '@/features/Sales/detailView/WholesalesForm/formInterfaces';
import {
  CreateWsCompanyReq,
  UpdateWsCompanyReq,
} from '@/features/Sales/detailView/WholesalesForm/WholesaleMgmtTab/WsCompanyModal/interfaces';
import { IDefaultFormValues } from '@/features/Sales/detailView/WholesalesForm/VehicleTab/default';
import { LhPhDetail } from '@/features/Sales/detailView/LhphFundingDetail/interfaces';
import {
  SalesInsurancePutReq,
  SalesInsuranceRes,
} from '@/features/Sales/detailView/tabs/ApplicationTab/subviews/insurance/interfaces';

export class SalesService extends AxiosService {
  public constructor() {
    super();
  }

  async getSalesSubviewList<T extends ISalesSubviewDb>(dto: PfsSubviewReqDto) {
    try {
      const { data } = await this.axios.post<any, AxiosResponse<GridData<T>>, PfsSubviewReqDto>(
        '/Sales/GetSalesSubviewList',
        dto
      );
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getLhPhDetailData(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<LhPhDetail>>(
        `/Sales/GetLhphData?appRecId=${appRecId}`
      );
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateLhPhDetailData(payload: LhPhSubmissionModel) {
    try {
      const { data } = await this.axios.put('/Sales/UpdateLhphDataItems', payload);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getQuoteData(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<QuoteData[]>>('/Sales/GetQuoteData', {
        params: { compId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCustomerTaxInfo(zip: string) {
    try {
      const { data } = await this.axios.get<ApiResponse<CustomerTaxInfo>>(
        '/Sales/GetCustomerTaxInfo',
        {
          params: { zip },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      //intentionally not re-throwing the error, the LeaseTerms page handles on return object == null
      return;
    }
  }

  async postNewSale(payload: postNewSalePayload) {
    try {
      const { data } = await this.axios.post('/Sales/CreateSale', payload);
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSalesDocList(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SalesDocListData[]>>(
        '/Sales/SalesDocsList',
        {
          params: { appRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getPresaleDocList(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SalesDocListData[]>>(
        '/Sales/PresalesDocsList',
        {
          params: { appRecId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async printSalesDoc(payload: PrintSalesDoc) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>('/Sales/PrintSalesDocs', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async sendSalesDocToCryptoSign(payload: SendSalesDocToCryptoSign) {
    try {
      const { data } = await this.axios.post<ApiResponse<{ success: boolean }>>(
        '/Sales/SendSalesDocsToCryptoSign',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async sendSalesDocToDocuSign(payload: { formIds: number[]; appRecId: number }) {
    try {
      const { data } = await this.axios.post<ApiResponse<{ envelope_id: string }>>(
        '/Sales/SendSalesDocsToDocuSign',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async sendSalesDocToSecureClose(payload: SendSalesDocsToSecureClose) {
    try {
      const { data } = await this.axios.post<ApiResponse<{ presentation_id: string }>>(
        '/Sales/SendSalesDocsToSecureClose',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSale(appRecID: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SaleData>>('/Sales/GetSale', {
        params: { appRecID },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getApplication(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ApplicationDb>>('/Sales/Application', {
        params: { appRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getAvailableVehicles(CompId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<IDefaultFormValues[]>>(
        'Sales/GetAvailableVehicles',
        {
          params: { CompId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSaleVehicle(recId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<IDefaultFormValues>>('Sales/SaleVehicle', {
        params: { recId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postSelectVehicle(payload: { appRecId: number; vehRecId: number }) {
    try {
      const { data } = await this.axios.post<ApiResponse<SaleData>>('Sales/SelectVehicle', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async printApplicationPdf(html: string) {
    try {
      const { data } = await this.axios.post(
        '/Sales/ApplicationPdf',
        {
          html,
        },
        {
          responseType: 'blob',
        }
      );
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postSaleData(payload: SaleData, appRecID: number) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>(
        `Sales/UpdateSale?AppRecId=${appRecID}`,
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getReferences(appRecID: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<Reference[]>>('/Sales/AppReferences', {
        params: { appRecID },
      });

      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postReference(payload: Reference) {
    try {
      await this.axios.post('Sales/AppReference', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateSaleManagement(payload: SaleManagement) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>(
        '/Sales/SaleManagement',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateBuyerApplication(payload: SaleBuyer) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>(
        '/Sales/BuyerApplication',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getEmployerHistoryByBuyerRecId(buyerRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<Employer[]>>('Sales/EmployerHistory', {
        params: { buyerRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateHasCobuyer(payload: UpdateCobuyer) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>('/Sales/HasCobuyer', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getResidenceHistory(buyerRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<PreviousAddress[]>>(
        '/Sales/AddressHistory',
        { params: { appBuyerRecId: buyerRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async createResidenceHistory(payload: PreviousAddressOptional) {
    try {
      await this.axios.post('/Sales/AddressHistory', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateResidenceHistory(payload: PreviousAddressOptional) {
    try {
      await this.axios.put('/Sales/AddressHistory', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async deleteResidenceHistory(recId: number) {
    try {
      await this.axios.delete('/Sales/AddressHistory', { params: { recId } });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateLeaseTerms(payload: LeaseTerms) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>('/Sales/LeaseTerms', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateFinanceTerms(payload: FinanceTerms) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>('/Sales/FinanceTerms', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateCashTerms(payload: CashTerms) { //CLEAN THIS IN BACK END STEP
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>('/Sales/CashTerms', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVerificationList(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<VerificationList[]>>(
        '/Sales/VerificationList',
        { params: { appRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getVerificationItems(contactType: string, contactRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<VerificationItem[]>>(
        '/Sales/VerificationItems',
        { params: { contactType, contactRecId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateVerification(payload: UpdateVerification) {
    try {
      await this.axios.put('/Sales/Verification', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getReferenceByRecId(recId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<Reference>>('/Sales/Reference', {
        params: { recId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateLandlord(payload: UpdateLandlord) {
    try {
      await this.axios.put('/Sales/Landlord', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getTradeInList(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<TradeIn[]>>('/Sales/TradeIn', {
        params: { appRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postTradeIn(payload: TradeIn) {
    try {
      await this.axios.post('/Sales/TradeIn', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateTradeIn(payload: TradeIn) {
    try {
      await this.axios.put('/Sales/TradeIn', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async deleteTradeIn(recId: number, appRecId: number) {
    try {
      this.axios.delete('/Sales/TradeIn', { params: { recId, appRecId } });
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async uploadScannedDocument(data: ScannedDocumentUpload) {
    const { file, ...rest } = data;
    try {
      const uuid = crypto.randomUUID();
      const fileType = '.' + file.name.split('.').pop();
      const fileNameCloud = `${uuid}${fileType}`;
      const fileNameLocal = file.name;
      const fileSize = file.size;
      const fileMime = file.type;

      const fileUrl = await systemService.uploadBlob(file, data.orgId, fileNameCloud);

      await this.axios.post('/Sales/ScannedDocument', {
        ...rest,
        fileNameLocal,
        fileNameCloud,
        fileSize,
        fileMime,
        fileUrl,
        fileType,
      });
      toast.success('Document uploaded');
    } catch (err) {
      console.error(err);
      toast.error('Unable to upload document');
      throw err;
    }
  }

  async getScannedDocs(orgId: number, appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ScannedDocument[]>>('/Sales/ScannedDocs', {
        params: { orgId, appRecId },
      });
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch scanned documents');
      console.error(e);
      throw e;
    }
  }

  async getScannedDocsByVehicle(orgId: number, vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ScannedDocument[]>>(
        '/Sales/ScannedDocsByVehicle',
        {
          params: { orgId, vehRecId },
        }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to fetch scanned documents by vehicle');
      console.error(e);
      throw e;
    }
  }

  async getPhotosByVehicle(orgId: number, vehRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ScannedDocument[]>>(
        '/Sales/PhotosByVehicle',
        {
          params: { orgId, vehRecId },
        }
      );
      return data.data;
    } catch (e) {
      toast.error('Unable to fetch Photos by vehicle');
      console.error(e);
      throw e;
    }
  }

  async printScannedDoc(orgId: number, blobName: string) {
    try {
      const fileUrl = await systemService.getBlobReadUrl(orgId, blobName);
      window.open(fileUrl);
    } catch (e) {
      toast.error('Unable to print document');
      console.error(e);
      throw e;
    }
  }

  async deleteScannedDoc(orgId: number, blobRecId: number) {
    try {
      await this.axios.delete<ApiResponse<ScannedDocument[]>>('/Sales/ScannedDocument', {
        params: { orgId, blobRecId },
      });
    } catch (e) {
      toast.error('Unable to delete document');
      console.error(e);
      throw e;
    }
  }

  async getSecureClosePresentations(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<SecureClosePresentation[]>>(
        '/Sales/GetSecureClosePresentations',
        { params: { appRecId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve SecureClose presentations');
      console.error(e);
      throw e;
    }
  }

  async postSale(payload: PostSale) {
    try {
      const { data } = await this.axios.post<ApiResponse<SaleData>>('/Sales/PostSale', payload);
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async unPostSale(appRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<SaleData>>(
        `/Sales/UnPostSale?appRecId=${appRecId}`
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getLienHolders(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<Lienholder[]>>(
        '/Sales/LienholdersByCompId',
        { params: { compId } }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCreditReportSettings(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CreditReportSettings>>(
        '/Sales/GetSevenHcAccountInfo',
        {
          params: { compId },
        }
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getDownPaymentList(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<DownPayment[]>>('/Sales/DownPaymentList', {
        params: { appRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      toast.error(`Error fetching down payments for application ID: ${appRecId}`);
      throw e;
    }
  }

  async printCreditInquiryAuth(appRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<string>>(
        '/Sales/PrintCreditInquiryAuthorization',
        {},
        {
          params: { appRecId },
        }
      );
      window.open(data.data!);
    } catch (e) {
      toast.error('Unable to print credit inquiry auth form');
      console.error(e);
      throw e;
    }
  }

  async updateAccountFromCreditReport(payload: UpdateAccountFromCreditReport) {
    try {
      await this.axios.post<ApiResponse<string>>('/Sales/UpdateAccountFromCreditReport', payload);
    } catch (e) {
      toast.error('Unable to update account');
      console.error(e);
      throw e;
    }
  }

  async runCreditReport(payload: CreditReportPayload) {
    try {
      const { data } = await this.axios.post<ApiResponse<CreditReportResponse>>(
        '/Sales/PullSevenHcCreditReport',
        payload
      );
      return data.data!.iFrameUrl;
    } catch (e) {
      toast.error('Unable to run credit report');
      console.error(e);
      throw e;
    }
  }

  async getPreviousCreditReports(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CreditReport[]>>(
        '/Sales/GetPreviousSevenHcCreditReports',
        {
          params: { appRecId },
        }
      );
      return data.data!.sort((a, b) => {
        return new Date(b.timestampUtc).valueOf() - new Date(a.timestampUtc).valueOf();
      });
    } catch (e) {
      toast.error('Unable to get previous credit reports');
      console.error(e);
      throw e;
    }
  }

  async updateVehicle(payload: Omit<IDefaultFormValues, 'milesOut'> & { milesOut?: string }) {
    try {
      await this.axios.put('/Sales/Vehicle', payload);
      toast.success('Vehicle Updated');
    } catch (e) {
      console.error(e);
      toast.error('There was an error submitting this vehicle');
      throw e;
    }
  }

  async declineApplication(payload: DeclineApplication) {
    try {
      await this.axios.put('/Sales/DeclineApplication', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async updateDeclined(payload: Declined) {
    try {
      await this.axios.put('/Sales/Declined', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getDeclined(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<Declined>>('/Sales/Declined', {
        params: { appRecId },
      });
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async leaseSaleCalc(payload: LeaseSaleCalc) {
    try {
      const { data } = await this.axios.post<ApiResponse<LeaseSaleCalcResults>>(
        '/Sales/LeaseSaleCalc',
        payload
      );
      return data.data!;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getSaleInsuranceInfo(appRecId: number) {
    try {
      const res = await this.axios.get<ApiResponse<SalesInsuranceRes>>('/Sales/GetInsuranceInfo', {
        params: { appRecId },
      });
      return res.data.data;
    } catch (e) {
      toast.error('Unable to fetch insurance information');
      console.error(e);
      throw e;
    }
  }

  async updateInsuranceInfo(insuranceInfoPutReq: SalesInsurancePutReq) {
    try {
      await this.axios.put('/Sales/UpdateInsuranceInfo', insuranceInfoPutReq);
      toast.success('Insurance information updated');
    } catch (e) {
      toast.error('Error updating insurance information');
      console.error(e);
      throw e;
    }
  }

  async updateLegalQuestions(payload: LegalQuestions) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>(
        '/Sales/LegalQuestions',
        payload
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to update legal questions');
      console.error(e);
      throw e;
    }
  }

  async getFinanceTaxRates(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<FinanceTaxRates>>(
        '/Sales/FinanceTaxRates',
        { params: { appRecId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to get tax rates');
      console.error(e);
      throw e;
    }
  }

  async updateFinanceTaxRates(payload: FinanceTaxRates) {
    try {
      const { data } = await this.axios.put<ApiResponse<SaleData>>(
        '/Sales/FinanceTaxRates',
        payload
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to update tax rates');
      console.error(e);
      throw e;
    }
  }

  async updateIncomeAssessment(payload: IncomeAssessment) {
    try {
      await this.axios.put('/Sales/IncomeAssessment', payload);
    } catch (e) {
      toast.error('Unable to update income assessment');
      console.error(e);
      throw e;
    }
  }

  async getCompanyUnderwriting(compId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<DclUnderwriting>>(
        '/Sales/DclUnderwriting',
        { params: { compId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve company underwriting information');
      console.error(e);
      throw e;
    }
  }

  async updateInterviewQuestions(data: InterviewQuestions) {
    try {
      await this.axios.put('/Sales/InterviewQuestions', data);
    } catch (e) {
      toast.error('Unable to update interview questions');
      console.error(e);
      throw e;
    }
  }

  async setHasQuote(appRecId: number) {
    try {
      await this.axios.put('/Sales/HasQuote', {}, { params: { appRecId } });
    } catch (e) {
      toast.error('Unable to update quote status');
      console.error(e);
      throw e;
    }
  }

  async getScoringQuestions() {
    try {
      const { data } = await this.axios.get<ApiResponse<SalesScoring[]>>(
        '/Sales/GetScoringQuestions'
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve scoring questions');
      console.error(e);
      throw e;
    }
  }

  async upsertApplicationScoring(payload: SalesScoringGridDto) {
    try {
      await this.axios.post('/Sales/MultipleApplicationScoring', payload);
    } catch (e) {
      toast.error('Unable to update sales scoring');
      console.error(e);
      throw e;
    }
  }

  async getApplicationScoring(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<ApplicationScoring[]>>(
        '/Sales/ApplicationScoring',
        { params: { appRecId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve application scoring');
      console.error(e);
      throw e;
    }
  }

  async dynamicScoringUpdate(payload: { appRecId: number }) {
    try {
      await this.axios.post('/Sales/DynamicScoringUpdate', payload);
    } catch (e) {
      toast.error('Unable to update scoring from application values');
      console.error(e);
      throw e;
    }
  }

  async updateManualUnderwritingSelection(payload: {
    appRecId: number;
    manualUnderwritingSelection: boolean;
  }) {
    try {
      await this.axios.put('/Sales/ManualUnderwritingSelection', payload);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async getCryptoSignSignatures(appRecId: number) {
    try {
      const { data } = await this.axios.get<ApiResponse<CryptoSignSignature[]>>(
        '/Sales/CryptoSignSignatures',
        { params: { appRecId } }
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve CryptoSign Signatures');
      console.error(e);
      throw e;
    }
  }

  async activateDeal(appRecId: number) {
    try {
      await this.axios.post<ApiResponse<unknown>>(`/Sales/ActivateDeal?appRecId=${appRecId}`);
    } catch (e) {
      toast.error('Unable to activate deal');
      console.error(e);
      throw e;
    }
  }

  async inactivateDeal(appRecId: number) {
    try {
      await this.axios.post<ApiResponse<unknown>>(`/Sales/InactivateDeal?appRecId=${appRecId}`);
    } catch (e) {
      toast.error('Unable to inactivate deal');
      console.error(e);
      throw e;
    }
  }

  async getWholesaleForm(appRecId?: string | null) {
    try {
      const appRecIdFmt = !appRecId ? '' : `?AppRecId=${appRecId}`;
      const { data } = await this.axios.get<ApiResponse<IWsFormRes>>(
        `/Sales/GetWholesaleForm${appRecIdFmt}`
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve Wholesale application');
      console.error(e);
      throw e;
    }
  }
  async getWholesaleFormAddlLists() {
    try {
      const { data } = await this.axios.get<ApiResponse<IWsFormAddlDataRes>>(
        '/Sales/GetWholesaleFormAddlLists'
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve additional data for Wholesale form');
      console.error(e);
      throw e;
    }
  }

  async postWholesale(reqBody: WsFormReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<IWsFormRes>>(
        '/Sales/PostWholesale',
        reqBody
      );
      toast.success('Wholesale posted');
      return data.data!;
    } catch (e) {
      toast.error('Error posting wholesale');
      console.error(e);
      throw e;
    }
  }
  async unpostWholesale(appRecId: number) {
    try {
      const { data } = await this.axios.post<ApiResponse<any>>(
        `/Sales/UnPostSale?appRecId=${appRecId}`
      );
      toast.success('Wholesale unposted');
      return data.data!;
    } catch (e) {
      toast.error('Error unposting wholesale');
      console.error(e);
      throw e;
    }
  }
  async createWholesaleCompany(reqBody: CreateWsCompanyReq) {
    try {
      const { data } = await this.axios.post<ApiResponse<any>>(
        `/Sales/CreateWholesaleCompany`,
        reqBody
      );
      toast.success('Wholesale company created');
      return data.data!;
    } catch (e) {
      toast.error('Error creating wholesale company');
      console.error(e);
      throw e;
    }
  }
  async updateWholesaleCompany(reqBody: UpdateWsCompanyReq) {
    try {
      const { data } = await this.axios.patch<ApiResponse<any>>(
        `/Sales/UpdateWholesaleCompany`,
        reqBody
      );
      toast.success('Wholesale company updated');
      return data.data!;
    } catch (e) {
      toast.error('Error updating wholesale company');
      console.error(e);
      throw e;
    }
  }

  async getDealsQuotesForm(appRecId?: string | null) {
    try {
      const nAppRecId = Number(appRecId);
      const appRecIdFmt = !appRecId || isNaN(nAppRecId) ? '' : `?AppRecId=${appRecId}`;
      const { data } = await this.axios.get<ApiResponse<DealData>>(
        `/Sales/GetDealsQuotesForm${appRecIdFmt}`
      );
      return data.data!;
    } catch (e) {
      toast.error('Unable to retrieve deal/quote application');
      console.error(e);
      throw e;
    }
  }

  async postDealQuotes(payload: DealData | null) {
    try {
      const { data } = await this.axios.post('/Sales/PostDealsQuotes', payload);

      if (!data) throw 'Error posting deal/quote';

      toast.success('Deal/Quote saved successfully');
      return data;
    } catch (e) {
      toast.error('Error posting deal/quote');
      console.error("Error posting deal/quote:", e);
      throw e;
    }
  }
}

export const salesService = new SalesService();
