import { SalesSubviewKey } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  WholesalesFilterKey,
  WholesalesSortKey,
  wholesalesFilterFields,
  wholesalesSortFields,
} from './dataModelConfig';
import { WholesalesDb } from './interfaces';
import { wholesalesTableColumns } from './tableColumns';

export const wholesalesSubviewConfig: SubviewInfo<WholesalesSortKey, WholesalesFilterKey> = {
  text: 'Wholesales',
  route: SalesSubviewKey.wholesales,
  columns: wholesalesTableColumns,
  dateFilterField: { field: 'saleDate', label: 'Date Sold' },
  initSortField: 'saleDate',
  sortableFields: wholesalesSortFields,
  filterableFields: wholesalesFilterFields,
  dbRowValidationFxn: (row: WholesalesDb) => WholesalesDb.new(row),
};
