import { FC, useEffect } from "react";
// components
import DetailViewLayout from "@/mui/components/layout/DetailViewLayout";
import DetailViewHeader from "@/mui/components/layout/DetailViewLayout/DetailViewHeader";
import NavTabs from "./layout/Header/NavTabs";
import LeftContainer from "./layout/LeftContainer";
import RightContainer from "./layout/RightContainer";
import QuickActionsModal from "./components/quickActionsModal/QuickActionsModal";
import DetailViewBody from "@/mui/components/layout/DetailViewLayout/DetailViewBody";
// state
import { useAccountSelector } from "../../accountSlice";
import AcctDetailsProvider, { useAcctDetailsCtx } from "./AccountDetailProvider";
// utils
import { customerService } from "@/services/customerService";
import { formatPhoneE164 } from "@/utils/helpers/general";
// interfaces
import { OptInData } from "../interfaces";

/** ### Layout for Account detail view */
const AccountDetail: FC = () => {
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  const accountInformationLoading = useAccountSelector((s) => s.accountInformationLoading);
  const setOptIn = useAcctDetailsCtx((s) => s.setOptIn);
  const setOptInAuthorization = useAcctDetailsCtx((s) => s.setOptInAuthorization);

  // Data loaders
  const fetchOptInWorkflow = async (appRecId: number) => {
    try {
      const res = await customerService.getOptInWorkflow(appRecId);
      if (!res)
        console.warn(
          `customerService.getOptInWorkflow(): No data returned for appRecId: ${appRecId}`
        );

      res && setOptInAuthorization(res);
    } catch {}
  };
  const fetchOptIn = async (appRecId: number) => {
    if (!accountInformation?.buyer.cellPhone) {
      console.error("Buyer's cell phone is undefined");
      return;
    }
    try {
      const phoneNumber = formatPhoneE164(accountInformation.buyer.cellPhone);
      const res = await customerService.getOptIn(phoneNumber, appRecId);

      res && setOptIn(OptInData.new(res));
    } catch {}
  };

  const appRecId = accountInformation?.appRecId;
  useEffect(() => {
    if (appRecId) {
      fetchOptInWorkflow(appRecId);
      fetchOptIn(appRecId);
    }
  }, [appRecId]);

  return (
    <DetailViewLayout backLink="/accounts">
      <DetailViewHeader title="Account Overview">
        <NavTabs hasTabs />
      </DetailViewHeader>

      <DetailViewBody direction="row" gap={2.5} p="10px" isLoading={accountInformationLoading}>
        <LeftContainer />
        <RightContainer />
      </DetailViewBody>

      <QuickActionsModal />
    </DetailViewLayout>
  );
};

const AccountDetailLayout: FC = () => (
  <AcctDetailsProvider>
    <AccountDetail />
  </AcctDetailsProvider>
);

export default AccountDetailLayout;
