import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// components
import PaymentMethodFields from "./PaymentMethodFields";
import BillingInfoFields from "./BillingInfoFields";
import OpenEdgeMpdFields from "./OpenEdgeMpdFields";
import CcBillingFields from "./CcBillingFields";
import AchAcctTypeField from "./AchAcctTypeField";
import AuthAndNotesSection from "./AuthAndNotesSection";
import SubmitButtonGroup from "./SubmitButtonGroup";

/** ###  */
const FormRightColumn: FC = () => (
  <Grid
    container
    direction="column"
    wrap="nowrap"
    sx={{ width: 380, maxWidth: 500, justifyContent: "space-between", gap: 3, flex: 1 }}
  >
    <Grid container direction="column" gap={1.5}>
      <PaymentMethodFields />
      <BillingInfoFields />
      <CcBillingFields />
      <OpenEdgeMpdFields />
      <AchAcctTypeField />
    </Grid>

    <Grid
      container
      direction="column"
      wrap="nowrap"
      sx={{ flex: 1, gap: 2, justifyContent: "end", justifySelf: "end" }}
    >
      <AuthAndNotesSection />
      <SubmitButtonGroup />
    </Grid>
  </Grid>
);

export default FormRightColumn;
