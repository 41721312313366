import { FC } from "react";
// kendo
import { Button } from "@/components/button/Button";
// utils
import { useNavUp } from "@/utils/routing/useNavUpEvent";

/** ###  */
const FormBackButton: FC = () => (
  <div style={{ maxWidth: "140px" }}>
    <Button fillMode="flat" label="< Go Back" onClick={useNavUp()} />
  </div>
);

export default FormBackButton;
