import { FC } from "react";
import { useMatch } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { alpha } from "@mui/system/colorManipulator";
import { buttonClasses } from "@mui/material/Button";
import MuiKendoButton from "@/mui/components/buttons/MuiKendoButton";
import NestedDetailLayout from "@/mui/components/layout/NestedDetailLayout";
import NestedDetailHeaderLayout from "@/mui/components/layout/NestedDetailHeaderLayout";
// components
import DownPaymentTable from "./DownPaymentTable";
import PaymentFormLayout from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/FormLayout";
// state
import { useSaleMgmtCtx } from "../SaleMgmtProvider";
import PmtFormProvider from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
import { useAppDispatch } from "@/store/store";
import { getSaleData } from "@/features/Sales/salesActionCreator";
// utils
import { useNavUp } from "@/utils/routing/useNavUpEvent";
import { getRouteParamNum } from "@/utils/routing/formatting";
import { DCS_BLUE } from "@/mui/theme/colors";

const defaultSx: SxProps = {
  "&:hover": { background: alpha(DCS_BLUE, 0.05) },
  background: "inherit",
  px: 0,
  pr: 1,
};

const DownPmtHeader: FC = () => (
  <NestedDetailHeaderLayout headerTitle="Down Payment">
    <MuiKendoButton onClick={useNavUp()}>Sale Management</MuiKendoButton>
  </NestedDetailHeaderLayout>
);

/** ###
 * @todo Prevent `getDownPaymentList()` from firing twice
 */
const DownPaymentView: FC = () => {
  const dispatch = useAppDispatch();
  const showDownPaymentForm = useSaleMgmtCtx((s) => s.showDownPaymentForm);
  const setShowDownPaymentForm = useSaleMgmtCtx((s) => s.setShowDownPaymentForm);
  const appRecId = getRouteParamNum(
    useMatch({ path: "/sales/postedSales/:appRecId/sale-management/down-payment" })?.params.appRecId
  );

  // Event handlers
  const handleClose = () => setShowDownPaymentForm(false);
  const handleSuccess = () => {
    appRecId && dispatch(getSaleData(appRecId));
    handleClose();
  };

  return (
    <PmtFormProvider handleSuccess={handleSuccess}>
      <NestedDetailLayout header={<DownPmtHeader />} p={0}>
        {showDownPaymentForm ? (
          <Grid container direction="column">
            <Grid container>
              <MuiKendoButton
                sx={{ ...defaultSx, [`& .${buttonClasses.startIcon}`]: { ml: 0, mr: -0.25 } }}
                disableElevation
                color="backLink"
                startIcon={<FaChevronLeft style={{ height: 10 }} />}
                onClick={handleClose}
              >
                Go Back
              </MuiKendoButton>
            </Grid>

            <PaymentFormLayout />
          </Grid>
        ) : (
          <DownPaymentTable />
        )}
      </NestedDetailLayout>
    </PmtFormProvider>
  );
};

export default DownPaymentView;
