import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import { Loader } from '@progress/kendo-react-all';
import { RadioButton } from '@progress/kendo-react-inputs';
import { SocialSecurityInput } from '@/components/inputs/socialSecurityInput/SocialSecurityInput';
import { Modal } from '@/components/modals/Modal';
import { Button } from '@/components/button/Button';
import { Checkbox } from '@/components/checkbox/Checkbox';
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import DateInput from "@/mui/components/form/MuiKendoDateField";
import { NumberInput } from '@/components/inputs/numberInput/NumberInput';
import { DropdownInput } from '@/components/inputs/dropdown/DropdownInput';
import { PhoneInput } from '@/components/inputs/phoneInput/PhoneInput';
import { Spacer } from '@/components/spacer/Spacer';
import { TextInput } from '@/components/inputs/text/TextInput';
import { ZipInput } from '@/components/inputs/zipInput/ZipInput';
import { BasicTable } from '@/components/table/Table';
import { SectionHeader } from '@/components/sectionHeader/SectionHeader';
import { MultipleInputRowRadioButton } from '@/features/Sales/components/multipleInputRow/MultipleInputRowRadioButton';
import { MultipleInputRow } from '@/features/Sales/components/multipleInputRow/MultipleInputRow';
import AddressAutocomplete from '@/components/inputs/addressAutocomplete/AddressAutocomplete';
// state
import { useApplicationBuyer } from './useApplicationBuyer';
// utils
import { optionalNotGreaterThanTodayValidation } from '@/utils/helpers/formValidation';
import { scheduleData } from '@/utils/helpers/general';
import { usaStateCodes } from '@/general/regions';
// interfaces
import { BuyerType } from '@/enums/general';
// style
import styles from './ApplicationBuyer.module.scss';
import applicationTabStyle from '../ApplicationTabs.module.scss';
import Sales from '@/features/Sales';
import { SaleBuyer } from '@/interfaces';

/** @deprecated needs refactor */
export const ApplicationBuyer: FC<{ buyerOrCobuyer: BuyerType; isCobuyer?: boolean }> = ({
  buyerOrCobuyer,
  
  isCobuyer = true,
}) => {
  const {
    control,
    typeOfResidenceData,
    watch,
    prevEmployerColumns,
    updateBuyerApplicationLoading,
    handleBuyerApplicationUpdate,
    handleCoBuyerApplicationUpdate,
    handleSubmit,
    editEmploymentHistoryModalOpen,
    handleCloseEditEmploymentHistoryModal,
    handleAddEmploymentHistory,
    setActiveEmploymentHistoryData,
    setEditEmploymentHistoryModalOpen,
    addEmploymentHistoryForm,
    activeEmploymentHistoryData,
    addEmploymentHistoryLoading,
    deleteEmploymentHistoryLoading,
    handleDeleteEmploymentHistory,
    prevEmployerData,
    prevEmployersLoading,
    handleUpdateEmploymentHistory,
    prevResidenceColumns,
    prevResidenceData,
    prevResidenceLoading,
    activeResidenceData,
    setActiveResidenceData,
    editPrevResidenceModalOpen,
    setEditPrevResidenceModalOpen,
    addResidenceHistoryForm,
    handleCloseEditResidenceHistoryModal,
    addResidenceHistoryLoading,
    deleteResidenceHistoryLoading,
    handleAddResidenceHistory,
    handleDeleteResidenceHistory,
    handleUpdateResidenceHistory,
    showMailingAddress,
    setShowMailingAddress,
    errors,
    handlePlaceSelected,
    handleEmployerPlaceSelected,
    handleResidenceHistorySelected,
    handleEmploymentHistorySelected,
    handleMailingPlaceSelected,
    fullAddress,
    fullEmployerAddress,
    fullMailingAddress,
    activeResidenceHistoryAddress,
    activeEmploymentHistoryAddress,
    isDisabled,
  } = useApplicationBuyer(buyerOrCobuyer);

  // @todo move nested components to separate files
  // @todo move component to file
  const EmploymentHistoryForm: FC = () => {
    return (
      <>
        <Spacer size={4} />
        <form
          className={applicationTabStyle.bodyContainer}
          onSubmit={
            activeEmploymentHistoryData
              ? addEmploymentHistoryForm.handleSubmit(handleUpdateEmploymentHistory)
              : addEmploymentHistoryForm.handleSubmit(handleAddEmploymentHistory)
          }
        >
          <div className={applicationTabStyle.bodyChild} style={{ gap: '64px' }}>
            <div className={applicationTabStyle.bodyChild}>
              <Controller
                name="employer"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <TextInput label="Employer" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="jobTitle"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <TextInput label="Occupation" readOnly={isDisabled} {...field} />
                )}
              />
              <div className={applicationTabStyle.inputRow}>
                <label className={applicationTabStyle.inputLabel}>Years/Months</label>
                <div className={applicationTabStyle.inputRowChild}>
                  <Controller
                    name="years"
                    control={addEmploymentHistoryForm.control}
                    render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                  />
                  <Controller
                    name="months"
                    control={addEmploymentHistoryForm.control}
                    render={({ field }) => (
                      <NumberInput readOnly={isDisabled} max={11} min={0} {...field} />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={applicationTabStyle.bodyChild}>
              <div className={applicationTabStyle.bodyChild}>
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>Previous or Current</label>
                  <div className={applicationTabStyle.radioButton}>
                    <Controller
                      name="currentPrevious"
                      control={addEmploymentHistoryForm.control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Previous"
                            defaultChecked
                            value="Previous"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="currentPrevious"
                      control={addEmploymentHistoryForm.control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Current"
                            value="Current"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>Employment Type</label>
                  <div className={applicationTabStyle.radioButton}>
                    <Controller
                      name="employmentType"
                      control={addEmploymentHistoryForm.control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Full Time"
                            defaultChecked
                            value="Full Time"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="employmentType"
                      control={addEmploymentHistoryForm.control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Part Time"
                            disabled={isDisabled}
                            value="Part Time"
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <Controller
                name="payFrequency"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <DropdownInput
                    label="Pay Period"
                    data={scheduleData}
                    disabled={isDisabled}
                    {...field}
                  />
                )}
              />
              <Controller
                name="salary"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <CurrencyInput label="Take Home Pay" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="nextPayDay"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <DateInput label="Next Pay Day" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="paidOn"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <TextInput label="Paid On" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="hours"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <TextInput label="Hours/Shifts Worked" readOnly={isDisabled} {...field} />
                )}
              />
              <Controller
                name="phoneNumber"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <PhoneInput label="Employer Phone" readOnly={isDisabled} {...field} />
                )}
              />
            </div>
          </div>
          <div className={applicationTabStyle.bodyChild} style={{ gap: '64px' }}>
            <div className={applicationTabStyle.bodyChild}>
              <Controller
                name="address"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <AddressAutocomplete
                    label="Employer Address"
                    readOnly={isDisabled}
                    {...field}
                    onPlaceSelected={handleEmploymentHistorySelected}
                    fullAddress={activeEmploymentHistoryAddress}
                  />
                )}
              />
              <Controller
                name="city"
                control={addEmploymentHistoryForm.control}
                render={({ field }) => (
                  <TextInput label="Employer City" readOnly={isDisabled} {...field} />
                )}
              />
              <div className={applicationTabStyle.inputRow}>
                <label className={applicationTabStyle.inputLabel}>Employer State/Zip</label>
                <div className={applicationTabStyle.inputRowChild}>
                  <Controller
                    name="state"
                    control={addEmploymentHistoryForm.control}
                    render={({ field }) => (
                      <DropdownInput
                        suggest
                        data={usaStateCodes}
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="zip"
                    control={addEmploymentHistoryForm.control}
                    render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                  />
                </div>
              </div>
            </div>
            <div
              className={applicationTabStyle.bodyChild}
              style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <div className={styles.submitButtonContainer}>
                {!!activeEmploymentHistoryData && (
                  <Button
                    label="Delete"
                    themeColor="error"
                    buttonStyle={{ width: '160px' }}
                    type="button"
                    disabled={isDisabled}
                    loading={deleteEmploymentHistoryLoading}
                    onClick={handleDeleteEmploymentHistory}
                  />
                )}
                <Button
                  label={activeEmploymentHistoryData ? 'Update Employment' : 'Add Employment'}
                  buttonStyle={{ width: '160px' }}
                  fillMode="outline"
                  secondary
                  disabled={isDisabled}
                  loading={addEmploymentHistoryLoading}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  // @todo move nested components to separate files
  // @todo move component to file
  const ResidenceHistoryForm: FC = () => {
    return (
      <>
        <Spacer size={12} />
        <form
          className={applicationTabStyle.bodyContainer}
          onSubmit={
            activeResidenceData
              ? addResidenceHistoryForm.handleSubmit(handleUpdateResidenceHistory)
              : addResidenceHistoryForm.handleSubmit(handleAddResidenceHistory)
          }
        >
          <div className={applicationTabStyle.bodyChild}>
            <Controller
              name="address"
              control={addResidenceHistoryForm.control}
              render={({ field }) => (
                <AddressAutocomplete
                  label="Address"
                  readOnly={isDisabled}
                  {...field}
                  onPlaceSelected={handleResidenceHistorySelected}
                  fullAddress={activeResidenceHistoryAddress}
                />
              )}
            />
            <Controller
              name="city"
              control={addResidenceHistoryForm.control}
              render={({ field }) => <TextInput label="City" readOnly={isDisabled} {...field} />}
            />
            <div className={applicationTabStyle.inputRow}>
              <label className={applicationTabStyle.inputLabel}>State/Zip</label>
              <div className={applicationTabStyle.inputRowChild}>
                <Controller
                  name="state"
                  control={addResidenceHistoryForm.control}
                  render={({ field }) => (
                    <DropdownInput suggest data={usaStateCodes} disabled={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="zip"
                  control={addResidenceHistoryForm.control}
                  render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                />
              </div>
            </div>
            <Controller
              name="county"
              control={addResidenceHistoryForm.control}
              render={({ field }) => <TextInput readOnly={isDisabled} label="County" {...field} />}
            />
          </div>
          <div className={applicationTabStyle.bodyChild}>
            <div className={applicationTabStyle.inputRow}>
              <label className={applicationTabStyle.inputLabel}>Rent/Own</label>
              <div className={applicationTabStyle.radioButton}>
                <Controller
                  name="rentOwn"
                  control={addResidenceHistoryForm.control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Rent"
                        defaultChecked={addResidenceHistoryForm.watch('rentOwn') === 'Rent'}
                        value="Rent"
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="rentOwn"
                  control={addResidenceHistoryForm.control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        defaultChecked={addResidenceHistoryForm.watch('rentOwn') === 'Own'}
                        label="Own"
                        value="Own"
                        disabled={isDisabled}
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className={applicationTabStyle.inputRow}>
              <label className={applicationTabStyle.inputLabel}>Years/Months</label>
              <div className={applicationTabStyle.inputRowChild}>
                <Controller
                  name="years"
                  control={addResidenceHistoryForm.control}
                  render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                />
                <Controller
                  name="months"
                  control={addResidenceHistoryForm.control}
                  render={({ field }) => (
                    <NumberInput readOnly={isDisabled} max={11} min={0} {...field} />
                  )}
                />
              </div>
            </div>
            <Controller
              name="homeType"
              control={addResidenceHistoryForm.control}
              render={({ field }) => (
                <DropdownInput
                  label="Type of Residence"
                  disabled={isDisabled}
                  data={typeOfResidenceData}
                  {...field}
                />
              )}
            />
            <Controller
              name="rentMortgage"
              control={addResidenceHistoryForm.control}
              render={({ field }) => (
                <CurrencyInput label="Monthly Payment" readOnly={isDisabled} {...field} />
              )}
            />
            {addResidenceHistoryForm.watch('rentOwn') === 'Own' && (
              <Controller
                name="mortgageBalance"
                control={addResidenceHistoryForm.control}
                render={({ field }) => (
                  <CurrencyInput label="Mortgage Balance" readOnly={isDisabled} {...field} />
                )}
              />
            )}
            <div className={applicationTabStyle.inputRow}>
              <label className={applicationTabStyle.inputLabel}>Current/Past Due</label>
              <div className={applicationTabStyle.radioButton}>
                <Controller
                  name="currentPast"
                  control={addResidenceHistoryForm.control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Current"
                        defaultChecked
                        disabled={isDisabled}
                        value="Current"
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="currentPast"
                  control={addResidenceHistoryForm.control}
                  render={({ field: { onChange, ...restProps } }) => (
                    <div>
                      <RadioButton
                        {...restProps}
                        label="Past Due"
                        disabled={isDisabled}
                        value="Past Due"
                        onChange={(e) => onChange(e.value)}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <Spacer size={12} />
            <div
              className={applicationTabStyle.bodyChild}
              style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
            >
              <div className={styles.submitButtonContainer}>
                {!!activeResidenceData && (
                  <Button
                    label="Delete"
                    themeColor="error"
                    buttonStyle={{ width: '160px' }}
                    disabled={isDisabled}
                    type="button"
                    loading={deleteResidenceHistoryLoading}
                    onClick={handleDeleteResidenceHistory}
                  />
                )}
                <Button
                  label={activeResidenceData ? 'Update Residence' : 'Add Residence'}
                  buttonStyle={{ width: '160px' }}
                  fillMode="outline"
                  disabled={isDisabled}
                  secondary
                  loading={addResidenceHistoryLoading}
                />
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  // @todo move component to file
  const EditEmploymentHistoryModal: FC = () => {
    return (
      <Modal
        title="Previous Employment"
        isOpen={editEmploymentHistoryModalOpen}
        closeButton
        centerModal
        onCloseButtonClick={handleCloseEditEmploymentHistoryModal}
      >
        <EmploymentHistoryForm />
      </Modal>
    );
  };

  // @todo move component to file
  const EditResidenceHistoryModal: FC = () => {
    return (
      <Modal
        title="Residence History"
        isOpen={editPrevResidenceModalOpen}
        closeButton
        centerModal
        onCloseButtonClick={handleCloseEditResidenceHistoryModal}
      >
        <ResidenceHistoryForm />
      </Modal>
    );
  };
  const appRecId = 0;

  // @todo move nested components to separate files
  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit((data) => {
            const updatedData = {
              ...data,
              appRecId,
            };
            if (buyerOrCobuyer === 'Buyer') {
             
              return handleBuyerApplicationUpdate(updatedData);
            } else {
              

              return handleCoBuyerApplicationUpdate(updatedData);
            }
          })}
        >
          <section className={applicationTabStyle.section}>
            <SectionHeader title="General Information" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="First Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Middle Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Last Name" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="nickname"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Nickname" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="socialSecurityNumber"
                  control={control}
                  render={({ field }) => (
                    <SocialSecurityInput
                      label="Social Security #"
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="dob"
                  control={control}
                  rules={optionalNotGreaterThanTodayValidation}
                  render={({ field }) => (
                    <DateInput
                      label="Date of Birth"
                      errors={!!errors.dob?.message}
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="numberOfDependents"
                  control={control}
                  render={({ field }) => (
                    <NumberInput label="Number of Dependents" readOnly={isDisabled} {...field} />
                  )}
                />
              </div>
              <div className={`${applicationTabStyle.bodyChild} ${styles.rightGeneralContainer}`}>
                <div className={styles.rightContainerInfo}>
                  <Controller
                    name="driversLicenseNumber"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Driver's License #" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="expirationDate"
                    control={control}
                    render={({ field }) => (
                      <DateInput label="Expiration Date" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="State"
                        data={usaStateCodes}
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className={styles.rightContainerInfo}>
                  <Controller
                    name="cellPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Cell Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="homePhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Home Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="otherPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Other Phone Number" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Email" readOnly={isDisabled} {...field} />
                    )}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <SectionHeader title="Residency" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <AddressAutocomplete
                      label="Address"
                      onPlaceSelected={handlePlaceSelected}
                      fullAddress={fullAddress}
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="aptOrSuite"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Apt/Suite" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="City" readOnly={isDisabled} {...field} />
                  )}
                />
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>State/Zip</label>
                  <div className={applicationTabStyle.inputRowChild}>
                    <Controller
                      name="addressState"
                      control={control}
                      render={({ field }) => (
                        <DropdownInput
                          suggest
                          data={usaStateCodes}
                          disabled={isDisabled}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="addressZip"
                      control={control}
                      render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                    />
                  </div>
                </div>
                <Controller
                  name="county"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="County" readOnly={isDisabled} {...field} />
                  )}
                />
                <Checkbox
                  label="Different Mailing Address?"
                  boldLabel
                  defaultChecked={showMailingAddress}
                  disabled={isDisabled}
                  onChange={() => setShowMailingAddress((prev) => !prev)}
                />
                {showMailingAddress && (
                  <>
                    <Controller
                      name="mailingAddress"
                      control={control}
                      render={({ field }) => (
                        <AddressAutocomplete
                          label="Mailing Address"
                          {...field}
                          onPlaceSelected={handleMailingPlaceSelected}
                          fullAddress={fullMailingAddress}
                          readOnly={isDisabled}
                        />
                      )}
                    />
                    <Controller
                      name="mailingCity"
                      control={control}
                      render={({ field }) => (
                        <TextInput label="Mailing City" readOnly={isDisabled} {...field} />
                      )}
                    />
                    <MultipleInputRow label="Mailing State/Zip">
                      <Controller
                        name="mailingState"
                        control={control}
                        render={({ field }) => (
                          <DropdownInput data={usaStateCodes} disabled={isDisabled} {...field} />
                        )}
                      />
                      <Controller
                        name="mailingZip"
                        control={control}
                        render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                      />
                    </MultipleInputRow>
                  </>
                )}
              </div>
              <div className={applicationTabStyle.bodyChild}>
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>Rent/Own</label>
                  <div className={applicationTabStyle.radioButton}>
                    <Controller
                      name="rentOrOwn"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Rent"
                            defaultChecked={watch('rentOrOwn') === 'Rent'}
                            value="Rent"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="rentOrOwn"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            defaultChecked={watch('rentOrOwn') === 'Own'}
                            label="Own"
                            value="Own"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>Years/Months</label>
                  <div className={applicationTabStyle.inputRowChild}>
                    <Controller
                      name="rentOrOwnYears"
                      control={control}
                      render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                    />
                    <Controller
                      name="rentOrOwnMonths"
                      control={control}
                      render={({ field }) => (
                        <NumberInput readOnly={isDisabled} max={11} min={0} {...field} />
                      )}
                    />
                  </div>
                </div>
                <Controller
                  name="typeOfResidence"
                  control={control}
                  render={({ field }) => (
                    <DropdownInput
                      label="Type of Residence"
                      data={typeOfResidenceData}
                      disabled={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="landlordsName"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={watch('rentOrOwn') === 'Rent' ? "Landlord's Name" : 'Mortgage Holder'}
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="monthlyPayment"
                  control={control}
                  render={({ field }) => (
                    <CurrencyInput label="Monthly Payment" readOnly={isDisabled} {...field} />
                  )}
                />
                {watch('rentOrOwn') === 'Own' && (
                  <Controller
                    name="mortgageBalance"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput label="Mortgage Balance" readOnly={isDisabled} {...field} />
                    )}
                  />
                )}
                <div className={applicationTabStyle.inputRow}>
                  <label className={applicationTabStyle.inputLabel}>Current/Past Due</label>
                  <div className={applicationTabStyle.radioButton}>
                    <Controller
                      name="currentOrPastDue"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Current"
                            defaultChecked
                            value="Current"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="currentOrPastDue"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="Past Due"
                            value="Past Due"
                            disabled={isDisabled}
                            onChange={(e) => onChange(e.value)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <Controller
                  name="landlordsPhoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      label={
                        watch('rentOrOwn') === 'Rent'
                          ? "Landlord's Phone #"
                          : 'Mortgage Co. Phone #'
                      }
                      readOnly={isDisabled}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <div className={styles.tableContainer}>
              <SectionHeader title="Residence History" />
              {prevResidenceLoading ? (
                <div className={applicationTabStyle.loading}>
                  <Loader size="large" />
                </div>
              ) : (
                <BasicTable data={prevResidenceData} columns={prevResidenceColumns} />
              )}
              <Spacer size={12} />
              <div className={styles.buttonContainer}>
                <Button
                  buttonStyle={{ width: '140px' }}
                  label="Add Residence"
                  type="button"
                  disabled={isDisabled}
                  onClick={() => {
                    setActiveResidenceData(undefined);
                    setEditPrevResidenceModalOpen(true);
                  }}
                />
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <SectionHeader title="Signed Credit Authorization" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <Controller
                  name="signatureVerifiedBy"
                  control={control}
                  render={({ field }) => (
                    <TextInput label="Signature Verified By" readOnly={isDisabled} {...field} />
                  )}
                />
                <Controller
                  name="creditDateSigned"
                  control={control}
                  render={({ field }) => (
                    <DateInput label="Date Signed" readOnly={isDisabled} {...field} />
                  )}
                />
              </div>
              <div className={applicationTabStyle.bodyChild}></div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <SectionHeader title="Employment" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild} style={{ gap: '64px' }}>
                <div className={applicationTabStyle.bodyChild}>
                {buyerOrCobuyer === 'Buyer' ? (
                    <Controller
                      name="totalMonthlyNetIncomeBuyer"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          label="Total Monthly Net Income"
                          readOnly={isDisabled}
                          {...field}
                        />
                      )}
                    /> ) 
                  : (
                    <Controller
                      name="totalMonthlyNetIncomeCoBuyer"
                      control={control}
                      render={({ field }) => (
                          <CurrencyInput
                            label="Total Monthly Net Income"
                            readOnly={isDisabled}
                            {...field}
                          />
                        )}
                      />
                    )}
                  <Controller
                    name="primaryEmployer"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Primary Employer" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="occupation"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Occupation" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="supervisor"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Supervisor" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <div className={applicationTabStyle.inputRow}>
                    <label className={applicationTabStyle.inputLabel}>Years/Months</label>
                    <div className={applicationTabStyle.inputRowChild}>
                      <Controller
                        name="employmentYears"
                        control={control}
                        render={({ field }) => <NumberInput readOnly={isDisabled} {...field} />}
                      />
                      <Controller
                        name="employmentMonths"
                        control={control}
                        render={({ field }) => (
                          <NumberInput readOnly={isDisabled} max={11} min={0} {...field} />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={applicationTabStyle.bodyChild}>
                  <div className={applicationTabStyle.bodyChild}>
                    <div className={applicationTabStyle.inputRow}>
                      <label className={applicationTabStyle.inputLabel}>Employment Type</label>
                      <div className={applicationTabStyle.radioButton}>
                        <Controller
                          name="employmentType"
                          control={control}
                          render={({ field: { onChange, ...restProps } }) => (
                            <div>
                              <RadioButton
                                {...restProps}
                                label="Full Time"
                                defaultChecked={watch('employmentType') === 'Full Time'}
                                value="Full Time"
                                disabled={isDisabled}
                                onChange={(e) => onChange(e.value)}
                              />
                            </div>
                          )}
                        />
                        <Controller
                          name="employmentType"
                          control={control}
                          render={({ field: { onChange, ...restProps } }) => (
                            <div>
                              <RadioButton
                                {...restProps}
                                label="Part Time"
                                defaultChecked={watch('employmentType') === 'Part Time'}
                                value="Part Time"
                                disabled={isDisabled}
                                onChange={(e) => onChange(e.value)}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <Controller
                    name="payPeriod"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        label="Pay Period"
                        disabled={isDisabled}
                        data={scheduleData}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="takeHomePay"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput label="Take Home Pay" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="nextPayDay"
                    control={control}
                    render={({ field }) => (
                      <DateInput label="Next Pay Day" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="paidOn"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Paid On" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="hoursOrShiftWorked"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Hours/Shifts Worked" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="employerPhoneNumber"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput label="Employer Phone" readOnly={isDisabled} {...field} />
                    )}
                  />
                </div>
              </div>
              <div className={applicationTabStyle.bodyChild} style={{ gap: '64px' }}>
                <div className={applicationTabStyle.bodyChild}>
                  <Controller
                    name="employerAddress"
                    control={control}
                    render={({ field }) => (
                      <AddressAutocomplete
                        label="Employer Address"
                        {...field}
                        onPlaceSelected={handleEmployerPlaceSelected}
                        readOnly={isDisabled}
                        fullAddress={fullEmployerAddress}
                      />
                    )}
                  />
                  <Controller
                    name="employerCity"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Employer City" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <div className={applicationTabStyle.inputRow}>
                    <label className={applicationTabStyle.inputLabel}>Employer State/Zip</label>
                    <div className={applicationTabStyle.inputRowChild}>
                      <Controller
                        name="employerState"
                        control={control}
                        render={({ field }) => (
                          <DropdownInput
                            suggest
                            data={usaStateCodes}
                            disabled={isDisabled}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="employerZip"
                        control={control}
                        render={({ field }) => <ZipInput readOnly={isDisabled} {...field} />}
                      />
                    </div>
                  </div>
                </div>
                <div className={applicationTabStyle.bodyChild}>
                  <Controller
                    name="otherIncomeAmount"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput label="Other Income Amt." readOnly={isDisabled} {...field} />
                    )}
                  />
                  <Controller
                    name="otherIncomeSchedule"
                    control={control}
                    render={({ field }) => (
                      <DropdownInput
                        data={scheduleData}
                        label="Other Income Schedule"
                        disabled={isDisabled}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="otherIncomeSource"
                    control={control}
                    render={({ field }) => (
                      <TextInput label="Other Income Source" readOnly={isDisabled} {...field} />
                    )}
                  />
                  <p className={styles.disclaimer}>
                    Note: Alimony, Child Support, or Separate Maintenance Income need not be
                    revealed if you do not wish to have it considered as a basis for repaying this
                    obligation.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <div className={styles.tableContainer}>
              <SectionHeader title="Other/Previous Employers" />
              {prevEmployersLoading ? (
                <div className={applicationTabStyle.loading}>
                  <Loader size="large" />
                </div>
              ) : (
                <BasicTable data={prevEmployerData} columns={prevEmployerColumns} />
              )}
              <Spacer size={12} />
              <div className={styles.buttonContainer}>
                <Button
                  buttonStyle={{ width: '140px' }}
                  label="Add Employer"
                  type="button"
                  disabled={isDisabled}
                  onClick={() => {
                    setActiveEmploymentHistoryData(undefined);
                    setEditEmploymentHistoryModalOpen(true);
                  }}
                />
              </div>
            </div>
          </section>
          <section className={applicationTabStyle.section}>
            <SectionHeader title="Current Customer Info" />
            <div className={applicationTabStyle.bodyContainer}>
              <div className={applicationTabStyle.bodyChild}>
                <MultipleInputRowRadioButton label="Current or Previous Customer">
                  <Controller
                    name="currentOrPrevious"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          label="N/A"
                          defaultChecked={watch('currentOrPrevious') === 'N/A'}
                          {...restProps}
                          value="N/A"
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="currentOrPrevious"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          label="Previous"
                          defaultChecked={watch('currentOrPrevious') === 'Previous'}
                          {...restProps}
                          value="Previous"
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    name="currentOrPrevious"
                    control={control}
                    render={({ field: { onChange, ...restProps } }) => (
                      <div>
                        <RadioButton
                          label="Current"
                          defaultChecked={watch('currentOrPrevious') === 'Current'}
                          {...restProps}
                          value="Current"
                          onChange={(e) => onChange(e.value)}
                        />
                      </div>
                    )}
                  />
                </MultipleInputRowRadioButton>
                <Controller
                  name="lotLocation"
                  control={control}
                  render={({ field }) => <TextInput label="Which Dealership" {...field} />}
                />
                <Controller
                  name="numberOfPurchases"
                  control={control}
                  render={({ field }) => (
                    <NumberInput format="n0" label="Number of Purchases" {...field} />
                  )}
                />
              </div>
              <div className={applicationTabStyle.bodyChild}></div>
            </div>
          </section>
          <div className={applicationTabStyle.submitButton}>
            <div className={applicationTabStyle.sectionBar}></div>
            <Button
              label="Submit"
              buttonStyle={{ width: '140px' }}
              loading={updateBuyerApplicationLoading}
            />
          </div>
        </form>
      </div>
      <EditEmploymentHistoryModal />
      <EditResidenceHistoryModal />
    </>
  );
};
