import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { debounce } from "lodash-es";
// state
import { useAuthSelector } from "@/features/auth/authSlice";
import { useSalesViewCtx } from "../../SalesViewProvider";
import { useSalesListCtx } from "../SalesListProvider";
import { useTableCtx, ITableCtx } from "./TableProvider";
// utils
import { fetchMappedCompanies, fetchSalesList } from "./requests";
// interfaces
import { SalesSubviewKey } from "../../enums";

export const useSalesListViewData = () => {
  const subview = useParams()["subview"] as SalesSubviewKey;
  const compId = useAuthSelector((s) => s.compId);
  const viewState = useSalesViewCtx((s) => s);
  const tableState = useTableCtx((s) => s);
  const listViewState = useSalesListCtx((s) => s);

  // Effects
  useEffect(() => {
    if (tableState.sorts.length > 0) {
      fetchSalesList(subview, viewState, tableState, listViewState);
      fetchMappedCompanies(listViewState.setMappedCompaniesCt);
    }
  }, [
    // @note this prevents unnecessary calls, as objects/arrays (i.e. tableState.filters and tableState.sort) change on each render
    tableState.dateRangeFilter
      ? `${tableState.dateRangeFilter.from.toString()}${tableState.dateRangeFilter.to.toString()}`
      : "",
    tableState.filters.map((f) => `${f.field.toString()}-${f.operator}-${f.value}`).join(""),
    tableState.sorts.map((s) => `${s.field.toString()}-${s.dir}`).join(""),
    tableState.page.skip,
    tableState.page.take,
    tableState.pageSizeValue,
    listViewState.subviewInfo.route,
    compId,
  ]);

  // Debounce the request filtered by 'text search filter'
  const debouncedFetch = useCallback(
    debounce((subview: SalesSubviewKey, tableState: ITableCtx) => {
      fetchSalesList(subview, viewState, tableState, listViewState);
      fetchMappedCompanies(listViewState.setMappedCompaniesCt);
    }, 400),
    [subview]
  );
  useEffect(() => {
    if (tableState.textSearchFilter !== null) {
      !viewState.isLoading && tableState.sorts.length > 0 && debouncedFetch(subview, tableState);
    }
  }, [tableState.textSearchFilter]);

  return listViewState.salesListRows;
};
