import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
// utils
import { useSalesParams } from '../../utils';
import { salesService } from '@/services/salesService';
import { loadColRecId, loadPaymentDetailState, loadPaymentProviderStates } from './requests';
// interfaces
import { DownPayment, SaleData } from '@/interfaces/Sales';
import { SavedPmtMethodRes } from '@/interfaces/CreditCard';
import { GetPaymentData, PaymentProviders } from '@/services/paymentService';
import { CardProcessorName } from '@/enums/payment';

type IFormPaymentData = Omit<
  GetPaymentData,
  'repayCfeeModelTest' | 'repayCfeeModelProd' | 'cobuyerDob' | 'GpiCustStatmentEnabled'
>;

const isNum = (input?: string | null) =>
  input !== null && input !== undefined && input !== '' && !isNaN(Number(input));

export interface IWsPmtsViewCtx {
  // "Payments" view state
  isLoading: boolean;
  setIsLoading: (v: IWsPmtsViewCtx['isLoading']) => void;
  saleData: SaleData | null;
  setSaleData: (v: IWsPmtsViewCtx['saleData']) => void;
  paymentRows: DownPayment[];
  setPaymentRows: (v: IWsPmtsViewCtx['paymentRows']) => void;

  // Form reference state
  colRecId: number | null;
  setColRecId: (v: IWsPmtsViewCtx['colRecId']) => void;
  savedPaymentMethodsRes: SavedPmtMethodRes[];
  setSavedPaymentMethodsRes: (v: IWsPmtsViewCtx['savedPaymentMethodsRes']) => void;
  /** from GET /Payment/Data?colRecId */
  paymentData: IFormPaymentData | null;
  setPaymentData: (v: IWsPmtsViewCtx['paymentData']) => void;
  paymentProviderData: PaymentProviders | null;
  setPaymentProviderData: (v: IWsPmtsViewCtx['paymentProviderData']) => void;
  // Payment provider states
  /** @deprecated rename to `pmtProcessor` - similar field-names: `['processor', 'paymentProcessor', 'provider', 'pmtProvider', 'paymentProviderConfig', 'cardProcessor']` */
  provider: CardProcessorName | null;
  setProvider: (v: IWsPmtsViewCtx['provider']) => void;
  enabledProviders: CardProcessorName[];
  setEnabledProviders: (v: IWsPmtsViewCtx['enabledProviders']) => void;

  // Utility functions
  loadPmtViewState: (setIsLoading?: ((v: boolean) => void) | null) => Promise<void>;
  resetStates: () => void;
}

const WsPmtsViewCtx = createContext<IWsPmtsViewCtx | null>(null);

const WsPmtsViewProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { appRecId } = useSalesParams();

  // "Payments" view state
  const [isLoading, setIsLoading] = useState<IWsPmtsViewCtx['isLoading']>(false);
  const [saleData, setSaleData] = useState<IWsPmtsViewCtx['saleData']>(null);
  const [paymentRows, setPaymentRows] = useState<IWsPmtsViewCtx['paymentRows']>([]);

  // Form reference state
  const [colRecId, setColRecId] = useState<IWsPmtsViewCtx['colRecId']>(null);
  const [savedPaymentMethodsRes, setSavedPaymentMethodsRes] = useState<
    IWsPmtsViewCtx['savedPaymentMethodsRes']
  >([]);
  const [paymentData, setPaymentData] = useState<IWsPmtsViewCtx['paymentData']>(null);
  // Payment provider states
  const [paymentProviderData, setPaymentProviderData] =
    useState<IWsPmtsViewCtx['paymentProviderData']>(null);
  const [provider, setProvider] = useState<IWsPmtsViewCtx['provider']>(null);
  const [enabledProviders, setEnabledProviders] = useState<IWsPmtsViewCtx['enabledProviders']>([]);

  // Utility functions
  const loadPmtViewState: IWsPmtsViewCtx['loadPmtViewState'] = async (
    setIsLoading_ = setIsLoading
  ) => {
    const isAppRecIdNum = isNum(appRecId);

    if (appRecId && isAppRecIdNum) {
      setIsLoading_ !== null && setIsLoading_(true);
      try {
        const downPaymentListRes = await salesService.getDownPaymentList(Number(appRecId));
        const saleRes = await salesService.getSale(Number(appRecId));

        setPaymentRows(downPaymentListRes);
        setSaleData(saleRes);

        const colRecIdRes = await loadColRecId(appRecId, setColRecId, setSavedPaymentMethodsRes);

        await loadPaymentProviderStates(
          colRecIdRes,
          setPaymentProviderData,
          setEnabledProviders,
          setProvider
        );
        await loadPaymentDetailState(colRecIdRes, setPaymentData);

        // @note Errors are handled in request methods/functions
      } catch (err) {
      } finally {
        setIsLoading_ !== null && setIsLoading_(false);
      }
    }
  };

  const resetStates = () => {
    // Reset "Payments" view state
    setPaymentRows([]);
    setSaleData(null);

    // Reset form reference state
    setColRecId(null);
    setSavedPaymentMethodsRes([]);
    setPaymentData(null);
    setPaymentProviderData(null);
    setProvider(null);
    setEnabledProviders([]);
  };

  useEffect(() => {
    loadPmtViewState();

    // Cleanup
    return () => resetStates();
  }, [appRecId]);

  return (
    <WsPmtsViewCtx.Provider
      value={{
        // "Payments" view state
        isLoading,
        setIsLoading,
        saleData,
        setSaleData,
        paymentRows,
        setPaymentRows,

        // Form reference state
        colRecId,
        setColRecId,
        savedPaymentMethodsRes,
        setSavedPaymentMethodsRes,
        paymentData,
        setPaymentData,
        // Payment provider states
        paymentProviderData,
        setPaymentProviderData,
        provider,
        setProvider,
        enabledProviders,
        setEnabledProviders,

        // Utility functions
        loadPmtViewState,
        resetStates,
      }}
    >
      {children}
    </WsPmtsViewCtx.Provider>
  );
};

export default WsPmtsViewProvider;

export const useWsPmtsViewCtx = <T,>(selector: (state: IWsPmtsViewCtx) => T): T => {
  const ctx = useContext(WsPmtsViewCtx);
  if (!ctx) {
    throw new Error('useWsPmtsViewCtx must be used within WsPmtsViewProvider');
  }
  return selector(ctx);
};
