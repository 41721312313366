import { FC, useEffect, useState } from "react";
// mui
import PaymentModal from "../../PaymentModal";
// components
import OpenEdgeLayout from "./OpenEdgeLayout";
// state
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
// utils
import { getOpenEdgeNewCreditCard } from "./utils";

/** ### Requires `usePmtFormCtx` */
const OpenEdgeModal: FC = () => {
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);
  const isOpenEdgeModalOpen = usePmtFormCtx((s) => s.isOpenEdgeModalOpen);
  const handleSuccess = usePmtFormCtx((s) => s.handleSuccess);
  const setIsOpenEdgeModalOpen = usePmtFormCtx((s) => s.setIsOpenEdgeModalOpen);
  const paymentLogRecId = usePmtFormCtx((s) => s.paymentLogRecId);
  const openEdgeEnv = usePmtFormCtx((s) => s.paymentProviders.value?.openEdgeEnv ?? null);
  const pmtDataOpenEdgeApiKey = usePmtFormCtx((s) => s.paymentData.value?.openedgeApiKey);
  const providerConfigOpenEdgeApiKey = usePmtFormCtx(
    (s) => s.paymentProviders.value?.payfieldsApiKey
  );
  const openEdgeApiKey = pmtDataOpenEdgeApiKey ?? providerConfigOpenEdgeApiKey ?? null;

  const [isPostReqLoading, setIsPostReqLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    getOpenEdgeNewCreditCard(
      openEdgeApiKey,
      openEdgeEnv,
      paymentLogRecId,
      setIsRefreshing,
      setIsPostReqLoading,
      handleSuccess
    );
  }, [paymentLogRecId]);

  const handleClose = () => {
    setIsSubmitting(false);
    setIsOpenEdgeModalOpen(false);
  };

  return (
    <PaymentModal open={isOpenEdgeModalOpen} onClose={handleClose} title="OpenEdge Payment">
      <OpenEdgeLayout isPostReqLoading={isPostReqLoading} isRefreshing={isRefreshing} />
    </PaymentModal>
  );
};

export default OpenEdgeModal;
