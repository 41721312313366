import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import {  Checkbox, } from "@/components";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';
// utils
import { getCanWaiveFeeDeprec } from '@/utils/helpers/payment';
// interfaces
import { GetPaymentData } from '@/services/paymentService';

/** ###  */
const FeeFieldGroup: FC = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const provider = useWsPmtsViewCtx((s) => s.provider);
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);

  const canWaiveFee = getCanWaiveFeeDeprec(provider!, paymentData! as GetPaymentData);

  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
      <Grid component="span" sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>
        Conv. Fee
      </Grid>
      <Grid container sx={{ flex: 1.25, gap: "10px", alignItems: "center" }}>
        <Controller
          name="ConvFee"
          control={control}
          render={({ field }) => <CurrencyInput readOnly {...field} />}
        />
        {canWaiveFee && (
          <Controller
            name="WaiveConvFee"
            control={control}
            render={({ field }) => <Checkbox label="Waive Fee" {...field} />}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default FeeFieldGroup;
