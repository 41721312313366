import { FC, useEffect } from "react";
import dayjs from "dayjs";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Button } from "@/components/button/Button";
import { BasicTable } from "@/components/table/Table";
import { Icons } from "@/components/icons";
import FormLoader from "../../layout/FormLoader";
// state
import { useSalesSelector } from "@/features/Sales/salesSlice";
import { useSaleMgmtCtx } from "../SaleMgmtProvider";
// utils
import { formatCurrency } from "@/utils/helpers/general";
// style
import styles from "../SaleManagement.module.scss";
import { formContainer } from "../../layout/style";
import { paymentService } from "@/services/paymentService";
import { Column } from "@/components/table/TableInterface";
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";

// @todo use existing component
const DateCell: FC = (props: any) => {
  const value = props.dataItem[props.field];
  return (
    <td style={{ color: "#737373" }}>{value ? dayjs(value).utc().format("MM/DD/YYYY") : ""}</td>
  );
};

// @todo use existing component
const CurrencyCell: FC = ({ dataItem, field }: any) => (
  <td style={{ color: "#737373" }}>{dataItem[field] ? formatCurrency(dataItem[field]) : ""}</td>
);

// @todo use existing component
const PrintCell: FC = ({ dataItem }: any) => (
  <td style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <div
      onClick={async () => {
        const res = await paymentService.getReceiptUrl(dataItem.recId as number);
        window.open(res);
      }}
    >
      <Icons.Print className={styles.printCell} />
    </div>
  </td>
);

const downPaymentColumns: Column[] = [
  { field: "pmtDate", title: "Payment Date", cells: { data: DateCell } },
  { field: "payToFrom", title: "Paid By" },
  { field: "totalApplied", title: "Amt. Paid", cells: { data: CurrencyCell } },
  { field: "paidBy", title: "Pay Method" },
  { field: "takenBy", title: "Taken By" },
  { field: "reprintReceipt", title: " ", cells: { data: PrintCell } },
];

/** ###  */
const DownPaymentTable: FC = () => {
  const paymentData = usePmtFormCtx((s) => s.paymentData.value);
  const isLoading = usePmtFormCtx((s) => s.isLoading);

  const cod = useSalesSelector((s) => s.saleData.sale?.cod);
  const depositDown = useSalesSelector((s) => s.saleData.sale?.depositDown);
  const downPaymentList = useSaleMgmtCtx((s) => s.downPaymentList);
  const showDownPaymentForm = useSaleMgmtCtx((s) => s.showDownPaymentForm);
  const setShowDownPaymentForm = useSaleMgmtCtx((s) => s.setShowDownPaymentForm);

  useEffect(() => {
    if (!showDownPaymentForm) downPaymentList.load();
  }, [showDownPaymentForm]);

  if (isLoading || downPaymentList.isLoading) return <FormLoader />;
  return (
    <Grid container direction="column" sx={formContainer}>
      <div className={styles.bodyContainer}>
        <div className={styles.downPaymentInfoHeader}>
          <div>
            <span className={styles.boldLabel}>Cash Down Payment:</span> {formatCurrency(cod)}
          </div>
          <div>
            <span className={styles.boldLabel}>Paid:</span>{" "}
            {formatCurrency((cod || 0) - (paymentData?.tOfPBal || 0))}
          </div>
          <div>
            <span className={styles.boldLabel}>Balance Due:</span>{" "}
            {formatCurrency(paymentData?.tOfPBal)}
          </div>
          <div className={styles.collectButton}>
            <Button
              label="Collect"
              onClick={() => setShowDownPaymentForm(true)}
              disabled={paymentData?.tOfPBal === 0}
            />
          </div>
        </div>
        <div className={styles.downPaymentInfoHeader}>
          <div>
            <span className={styles.boldLabel}>Deposit Amount:</span> {formatCurrency(depositDown)}
          </div>
          <div>
            <span className={styles.boldLabel}>Paid:</span> {formatCurrency(0)}
          </div>
          <div>
            <span className={styles.boldLabel}>Balance Due:</span> {formatCurrency(0)}
          </div>
          {/* Why does this need to be here */}
          <div className={styles.collectButton}>
            <Button label="Collect" disabled />
          </div>
        </div>
      </div>

      {/* @todo Convert to MUI `<DataGrid />` */}
      <BasicTable data={downPaymentList.value || []} columns={downPaymentColumns} />
    </Grid>
  );
};

export default DownPaymentTable;
