import { FC, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// components
import PaymentModal from "../PaymentModal";
// state
import { usePmtFormCtx } from "@/features/Accounts/accountsSubviews/AccountDetail/components/PaymentForm/PmtFormProvider";
// utils
import { pollForReceipt } from "@/utils/helpers/payment";
import { SetState } from "@/interfaces/utilityTypes";

// Event handlers
const handleMessage =
  (paymentLogRecId: number | null, setReceiptUrl: SetState<string | null>) =>
  async (e: MessageEvent) => {
    if (paymentLogRecId === 0) {
      return console.error(
        "RepayModal handleMessage(): `paymentLogRecId` cannot be 0:",
        paymentLogRecId,
        e
      );
    }

    // @note Leave this console log - need to test in live system
    console.log("handling message:", { origin: e.origin, data: e.data, paymentLogRecId, event: e });
    // Bug was reported in QA r.e. this conditional (may need to update conditional to also include `dcstest1`)
    if (!e.origin || !e.origin.includes("repay"))
      return console.error("RepayModal handleMessage(): Invalid `e.origin`:", paymentLogRecId, e);
    if (!paymentLogRecId)
      return console.error(
        "RepayModal handleMessage(): Invalid `paymentLogRecId`:",
        paymentLogRecId,
        e
      );

    if (e.data === "PAYMENT_ERROR") {
      console.error("Error processing 'Repay' payment:", e);
      toast.error("Error processing 'Repay' payment");
    }

    if (e.data === "PAYMENT_SUCCESS") {
      await pollForReceipt(paymentLogRecId, setReceiptUrl);
    }
  };

/** ### Requires `usePmtFormCtx` */
const RepayModal: FC = () => {
  const setIsSubmitting = usePmtFormCtx((s) => s.setIsSubmitting);
  const handleSuccess = usePmtFormCtx((s) => s.handleSuccess);
  const repayIframeUrl = usePmtFormCtx((s) => s.repayIframeUrl);
  const setRepayIframeUrl = usePmtFormCtx((s) => s.setRepayIframeUrl);
  const paymentLogRecId = usePmtFormCtx((s) => s.paymentLogRecId);
  const receiptUrl = usePmtFormCtx((s) => s.receiptUrl);
  const setReceiptUrl = usePmtFormCtx((s) => s.setReceiptUrl);

  // @todo Ref may be unnecessary - test below and remove if successful
  const cbRef = useRef(handleMessage(paymentLogRecId, setReceiptUrl));
  useEffect(() => {
    cbRef.current = handleMessage(paymentLogRecId, setReceiptUrl);
    // @todo Test -> replace with `handleMessage(paymentLogRecId, setReceiptUrl)(e)`
    const cb = (e: MessageEvent) => cbRef.current(e);

    if (!paymentLogRecId) {
      window.removeEventListener("message", cb);
    } else {
      window.addEventListener("message", cb);
    }

    return () => {
      window.removeEventListener("message", cb);
    };
  }, [repayIframeUrl, paymentLogRecId]);

  // @todo Remove. this runs in the payment context
  useEffect(() => {
    repayIframeUrl && receiptUrl && handleSuccess();
  }, [receiptUrl, repayIframeUrl]);

  if (!repayIframeUrl) return <></>;

  const handleClose = () => {
    setIsSubmitting(false);
    setRepayIframeUrl("");
  };

  return (
    <PaymentModal open={!!repayIframeUrl} onClose={handleClose} title="Repay Payment">
      <Grid sx={{ width: "800px", height: "78vh", marginTop: "30px" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={repayIframeUrl}
          title="Repay Credit Card Payment"
        ></iframe>
      </Grid>
    </PaymentModal>
  );
};

export default RepayModal;
