import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
// utils
import { salesService } from '@/services/salesService';
import { systemService } from '@/services/systemService';
// interfaces
import { DtoFilter, DtoPage, PfsSubviewReqDto } from '@/interfaces/requests';
import { SalesSubviewKey } from '../../enums';
import { ISalesViewCtx } from '../../SalesViewProvider';
import { ITableCtx } from './TableProvider';
import { ISalesListCtx } from '../SalesListProvider';
import { ISalesSubviewDb, ISalesSubviewDbCommon } from '../../interfaces';

/** ### Fetch information to be rendered in the list view */
export const fetchSalesList = async <T extends ISalesSubviewDbCommon = ISalesSubviewDb>(
  route: SalesSubviewKey,
  viewState: ISalesViewCtx,
  tableState: ITableCtx,
  listViewState: ISalesListCtx<T>
) => {
  const { setIsLoading } = viewState;
  const {
    activeSaleType,
    activeAppStatus,
    getDateRangeDtoFilters,
    page,
    queryPageNumber,
    sorts,
    textSearchFilter,
  } = tableState;

  const {
    setDbCt,
    setSalesListRows,
    subviewInfo: { dateFilterField, categoryFilter },
  } = listViewState;

  // Validation
  if (tableState.isDateFilterError) return;

  setIsLoading(true);

  try {
    // @todo move this logic to the state
    const dateRangeFilters = dateFilterField ? getDateRangeDtoFilters(dateFilterField.field) : [];
    const allFilters = [...dateRangeFilters];

    if (activeSaleType !== 'All') {
      const saleTypeFilter = DtoFilter.new({
        field: 'saleType',
        operator: '=',
        value: activeSaleType,
      });
      allFilters.push(saleTypeFilter);
    }

    if (categoryFilter && categoryFilter.field === 'appStatus') {
      const appStatusMapped = activeAppStatus.map((s) => `'${s}'`);
      const appStatusFilterValue = ` ( ${appStatusMapped.join(',')} ) `;
      const appStatusFilter = DtoFilter.new({
        field: 'appStatus',
        operator: 'IN',
        value: appStatusFilterValue,
      });
      allFilters.push(appStatusFilter);
    }

    // @todo fix on backend: if using "skip" (represents number of rows), then the page number should be calculated on the backend
    //    - because currently were calculating the page-number on the frontend, and then doing the reverse calc on the backend
    const dtoPage: DtoPage = { ...page, skip: queryPageNumber };
    const dto = PfsSubviewReqDto.new<SalesSubviewKey, any, any>({
      subview: route,
      filters: allFilters,
      sorts,
      page: dtoPage,
      search: textSearchFilter!,
    });

    const sales = await salesService.getSalesSubviewList(dto);
    setSalesListRows(sales?.data || []);
    setDbCt(sales?.total || 0);
  } catch (err_: unknown) {
    const err = err_ as AxiosError<any>;
    if (err.code === 'ERR_NETWORK') {
      toast.error('Network error');
    } else if (err.response?.data.message.includes('System.IndexOutOfRangeException')) {
      setSalesListRows([]);
      setDbCt(0);
      toast.warn('No rows returned');
    } else {
      console.error(err.code);
      toast.error('Failed to load sales');
    }
  }

  setIsLoading(false);
};

export const fetchMappedCompanies = async (
  setMappedCompaniesCt: (mappedCompaniesCt: number) => void
) => {
  try {
    const res = await systemService.mappedCompanies();
    setMappedCompaniesCt(res.length);
  } catch (err) {
    // @todo properly handle error
    console.error(err);
  }
};
