import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { HubConnection } from '@microsoft/signalr';
// kendo
import { ListView, Loader } from '@progress/kendo-react-all';
import { DropDownButton } from '@/components/dropDownButton/DropDownButton';
import { Modal } from "@/components/modals/Modal";
import { Spacer } from "@/components/spacer/Spacer";
import { Icons } from '@/components/icons';
// components
import AuthSettingsSelector from '@/features/Settings/AuthSettingsSelector';
import NavigationItem from '@/features/RootView/NavSidebar/NavigationItem';
import SupportWidget from '@/features/RootView/SupportWidget';
// state
import { useAppDispatch } from '@/store/store';
import { authActions, useAuthSelector } from '@/features/auth/authSlice';
import { useAuthCtx } from '@/AppProviders/AuthProvider';
import { useNotificationsCtx } from '@/features/notifications/NotificationsProvider';
// utils
import { isStandalone } from '@/env';
import { alphaLogout } from '@/utils/helpers/alpha';
import { getTimeAgo } from '@/utils/helpers/general';
import { connectSignalR, disconnectSignalR } from '@/features/notifications/utils';
import { initAuthState } from './request';
// interfaces
import { UnreadSMS } from '@/features/notifications/interfaces';
import { SelectableCompany } from '@/interfaces/System';
import { ActivityType } from '../activityPanel/activityPanelInterfaces';
// style
import styles from './AuthenticatedContainer.module.scss';
import Logo from '@/assets/dcsLogo.png';

const AuthenticatedContainer: FC = () => {
  const navigate = useNavigate();
  const origPath = useLocation().pathname;
  const dispatch = useAppDispatch();
  // Auth state
  const setUserInfo = useAuthCtx((s) => s.setUserInfo);
  const standaloneLogout = useAuthCtx((s) => s.logout);
  const locId = useAuthSelector((s) => s.locId);
  const compId = useAuthSelector((s) => s.compId);
  const userEmail = useAuthSelector((s) => s.userEmail);
  const selectableCompanies = useAuthSelector((s) => s.selectableCompanies);
  const companySelectorOpen = useAuthSelector((s) => s.companySelectorOpen);

  const loadingBffUser = useAuthCtx((s) => s.loadingBffUser);
  const selectedCompanyName = useAuthCtx((s) => s.selectedCompany?.companyName);

  const isLoggedInStandalone = useAuthCtx((s) => s.isLoggedIn);
  /** @deprecated - remove post-alpha */
  const isLoggedInDeprec = useAuthSelector((s) => s.isLoggedInDeprec);
  const isLoggedIn = isLoggedInStandalone || isLoggedInDeprec;

  // Noti state
  const unreadSmsOpen = useNotificationsCtx((s) => s.unreadSmsOpen);
  const setUnreadSmsOpen = useNotificationsCtx((s) => s.setUnreadSmsOpen);
  const loadUnreadSms = useNotificationsCtx((s) => s.loadUnreadSms);
  const unreadSms = useNotificationsCtx((s) => s.unreadSms);
  const selectedSms = useNotificationsCtx((s) => s.selectedSms);
  const setSelectedSms = useNotificationsCtx((s) => s.setSelectedSms);
  const { signalRConnection, setSignalRConnection } = useAuthCtx((s) => s);
  const { unreadSmsCount, setUnreadSmsCount } = useAuthCtx((s) => s);
  // Layout state
  const [navExpanded, setNavExpanded] = useState(false);
  const [loadingSms, setLoadingSms] = useState(false);

  const containerClass = `${styles.container} ${navExpanded ? styles.full : styles.minimal}`;
  const headerClass = `${styles.standaloneHeader} ${navExpanded ? styles.full : styles.minimal}`;

  // Event handlers
  const handleNavToggle = (expanded: boolean) => {
    // Close popups - looks weird having them stay open when the nav width changes
    dispatch(authActions.setCompanySelectorOpen(false));
    setUnreadSmsOpen(false);
    setNavExpanded(expanded);
  };

  const handleCompanySelect = (selectedCompany: SelectableCompany) => {
    disconnectSignalR(signalRConnection, setSignalRConnection);

    dispatch(authActions.setCompanySelectorOpen(false));
    // @todo remove post-alpha: `selectedCompId` should be managed with state
    localStorage.setItem("selectedCompId", selectedCompany.compId.toString());
    /* @todo Remove post-alpha
      Do a full-page reload, navigating to the root route
      It would be nice if we could just update the org/comp/loc id's in auth state and not have to reload
      or change the route, but there are two problems with that:

      1. Alpha performed a full-page reload when switching companies, and we did not plan for making everything re-fetch when the company changed
      2. More importantly, there are a lot of gotchas about retaining the current route when switching companies
         e.g. if a user is viewing, say, a particular account, and that account is not available in their newly selected company

      When the page is reloaded, the useEffect in Router.tsx will pull their selectedCompId from localStorage, and use that company
    */
    window.location.href = '/';
  };

  const handleSMSClick = (sms: UnreadSMS) => {
    if (sms.collectionRecords.length === 1) {
      navigate(
        `/accounts/${sms.collectionRecords[0]!.colRecId}?activityType=${ActivityType.Message}`,
        { state: { activityType: ActivityType.Message } }
      );
    } else {
      setSelectedSms(sms);
    }
    setUnreadSmsOpen(false);
  };

  const closeSMSAccountModal = () => {
    setSelectedSms(null);
  };

  const handleSmsCounter = async (smsCount: number) => {
    await setUnreadSmsCount(smsCount);
  }

  const handleSmsOpen = async (open: boolean) => {
    await setUnreadSmsOpen(open);

    if(open && unreadCount != unreadSmsCount) {
      setLoadingSms(true);
      await loadUnreadSms();
      setLoadingSms(false);
    }
  }

  // Effects
  useEffect(() => {
    if (!loadingBffUser && isLoggedInStandalone === false) {
      navigate({ pathname: "/auth/login", search: `from=${origPath}` });
      // `isLoggedInStandalone` is null when response has not been set
    } else if (!loadingBffUser && isLoggedInStandalone === true) {
      initAuthState(dispatch, isLoggedIn, setUserInfo);
    } else if (!loadingBffUser && isStandalone === false && isLoggedInDeprec === true) {
      initAuthState(dispatch, isLoggedIn, setUserInfo);
    }
  }, [isLoggedIn, loadingBffUser]);

  useEffect(() => {
    connectSignalR(signalRConnection, setSignalRConnection, locId, dispatch, handleSmsCounter);

    return () => {
      disconnectSignalR(signalRConnection, setSignalRConnection);
    };
  }, [compId]);

  const unreadCount = unreadSms
    ? unreadSms.reduce((accumulator, current) => {
        return accumulator + (current.unreadCount ?? 0);
      }, 0)
    : 0;

  return (
    <div className={'authContainerWithTopHeader'}>
      {isStandalone && (
        <div className={headerClass}>
          <h1 style={{ fontSize: '20px' }}>{selectedCompanyName}</h1>
          <AuthSettingsSelector />
        </div>
      )}
      <div className={containerClass}>
        <div className={styles.navigationContainer}>
          <div className={styles.arrowButtonContainer}>
            {navExpanded ? (
              <div className={styles.arrowButton} onClick={() => handleNavToggle(false)}>
                <Icons.ArrowLeft className={styles.arrowIcon} />
              </div>
            ) : (
              <div className={styles.arrowButton} onClick={() => handleNavToggle(true)}>
                <Icons.ArrowRight className={styles.arrowIcon} />
              </div>
            )}
          </div>
          <div className={styles.navigationHeader}>
            {isStandalone && selectableCompanies?.length ? (
              <div>
                <span className={styles.line} />
                <DropDownButton
                  buttonProps={{
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: navExpanded ? 'flex-start' : 'center',
                      flex: 1,
                    },
                  }}
                  anchorStyles={{ display: 'flex', flex: 1 }}
                  popUpProps={{
                    popupAlign: { vertical: 'center', horizontal: 'left' },
                    anchorAlign: { vertical: 'center', horizontal: 'right' },
                  }}
                  label={
                    <div className={styles.clickableLogo}>
                      <img src={Logo} className={styles.logo} />
                    </div>
                  }
                  open={companySelectorOpen}
                  setOpen={(open) => dispatch(authActions.setCompanySelectorOpen(open))}
                >
                  <div className={styles.companySelectorContainer}>
                    <div className={styles.companySelectorTitle}>Select Company</div>
                    <ListView
                      data={[...selectableCompanies].sort((a, b) => a.companyName.localeCompare(b.companyName))}
                      item={({ dataItem: company }: { dataItem: SelectableCompany }) => (
                        <div
                          onClick={() => handleCompanySelect(company)}
                          className={
                            company.compId === compId
                              ? styles.selectedCompany
                              : styles.selectableCompany
                          }
                        >
                          {company.companyName}
                        </div>
                      )}
                    />
                  </div>
                </DropDownButton>
              </div>
            ) : (
              <img src={Logo} className={styles.logo} />
            )}

            {navExpanded && (
              <div className={styles.textContainer}>
                <div className={styles.brand}>Dealer Controlled Solutions</div>
                {!!userEmail && <div className={styles.email}>{userEmail}</div>}
              </div>
            )}
          </div>
          <div className={styles.hr} />
          <Spacer size={8} />
          <NavigationItem Icon={Icons.Users} name="Accounts" route="/accounts" />
          {/* <NavigationItem Icon={Icons.ShieldCheck} name="Admin" route="/admin" /> */}
          <NavigationItem Icon={Icons.Collections} name="Collections" route="/collections" />
          <NavigationItem Icon={Icons.Graph} name="Reports" route="/reports" />
          <NavigationItem Icon={Icons.Sales} name="Sales" route="/sales" />
          <NavigationItem Icon={Icons.Car} name="Inventory" route="/inventory" />
          <NavigationItem Icon={Icons.Gauge} name="Dashboard" route="/dashboard" />
          <div className={`${styles.notificationContainer} ${unreadSmsOpen ? styles.active : ''}`}>
            <span className={styles.line} />
            <DropDownButton
              title="Notifications"
              buttonProps={{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: navExpanded ? 'flex-start' : 'center',
                  flex: 1,
                },
              }}
              anchorStyles={{ display: 'flex', flex: 1 }}
              popUpProps={{
                popupAlign: { vertical: 'bottom', horizontal: 'left' },
                anchorAlign: { vertical: 'center', horizontal: 'right' },
                //className: styles.popupContainer,
              }}
              label={
                <>
                  <div className={styles.notificationsIconContainer}>
                    <Icons.Bell />
                    {!!unreadSmsCount && unreadSmsCount > 0 && (
                      <span
                        className={styles.unreadIndicator}
                        style={{ right: unreadSmsCount > 99 ? '-10px' : '-2px' }}
                      >
                        {unreadSmsCount > 99 ? '99+' : unreadSmsCount}
                      </span>
                    )}
                  </div>
                  <span className={styles.navigationItemTitle}>Notifications</span>
                </>
              }
              open={unreadSmsOpen}
              setOpen={handleSmsOpen}
            >
              <div className={styles.unreadSMSContainer}>
                {
                  loadingSms ? 
                    <div style={{ padding: 10 }}><Loader size="medium" /></div>
                   :
                    <>
                      {!unreadSms.length ? (
                          <div style={{ padding: 10 }}>No new messages</div>
                        ) : (
                          <ListView
                            data={unreadSms}
                            item={({ dataItem }) => (
                              <div className={styles.smsContainer} onClick={() => handleSMSClick(dataItem)}>
                                <div>
                                  <Icons.Message />
                                </div>
                                <div>
                                  <div className={styles.bold}>{dataItem.name}</div>
                                  <div className={styles.bold}>"{dataItem.message}"</div>
                                  <div className={styles.italic}>{getTimeAgo(dataItem.dateTime)}</div>
                                </div>
                                <div className={styles.unreadCount}>{dataItem.unreadCount}</div>
                              </div>
                            )}
                          />
                        )}
                    </>
                }
              </div>
            </DropDownButton>
          </div>
          <Spacer expand />
          <div className={styles.hr} />
          <NavigationItem
            Icon={Icons.Logout}
            name="Logout"
            action={isStandalone ? standaloneLogout : alphaLogout}
          />
        </div>

        <div className={styles.mainContent}>
          {!!selectedSms && (
            <Modal
              isOpen={!!selectedSms}
              onCloseButtonClick={closeSMSAccountModal}
              onDimmerClick={closeSMSAccountModal}
              centerModal
              closeButton
            >
              <h3>Phone #{selectedSms?.phoneNumber} has multiple associated accounts:</h3>
              {selectedSms.collectionRecords.map((rec) => (
                <div style={{ marginBottom: '5px' }} key={rec.colRecId}>
                  <Link
                    to={`/accounts/${rec.colRecId}?activityType=${ActivityType.Message}`}
                    onClick={closeSMSAccountModal}
                  >
                    #{rec.stockNum} - {rec.buyerNames} - {rec.vehicle}
                  </Link>
                </div>
              ))}
            </Modal>
          )}
          {!compId ? (
            <div className={styles.center}>
              <Loader size="large" />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
        <div className={styles.hr} />

        <Spacer size={8} />

        <SupportWidget />
      </div>
    </div>
  );
};

export default AuthenticatedContainer;
