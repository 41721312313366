import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// kendo
import {
  GridColumnProps,
  GridCustomCellProps,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  Loader,
} from '@progress/kendo-react-all';
import { Icons } from '@/components/icons';
import { Column } from '@/components/table/TableInterface';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAppDispatch } from '@/store/store';
import { useSalesSelector } from '@/features/Sales/salesSlice';
import { getPresaleDocList, getSalesDocList } from '@/features/Sales/salesActionCreator';
// utils
import { salesService } from '@/services/salesService';
import { formatDate } from '@/utils/helpers/general';
// interfaces
import { ScannedDocument } from '@/interfaces';
import { AppStatus } from '@/features/Sales/enums';
// style
import styles from './SalesDocuments.module.scss';
import cryptonLogo from '@assets/crypton.webp';
import secureCloseLogo from '@assets/secureclose.png';

/** @deprecated likely duplicate */
const DateCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>{formatDate(field && (dataItem[field] as string))}</td>
);
/** @deprecated likely duplicate */
const DocusignCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>{field && dataItem[field] && <Icons.DocuSign width="125px" />}</td>
);
/** @deprecated likely duplicate */
const CryptoSignCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>
    {field && dataItem[field] && (
      <img className={styles.cryptosign} src={cryptonLogo} alt="Crypton Logo" />
    )}
  </td>
);
/** @deprecated likely duplicate */
const SecureCloseCell: FC<GridCustomCellProps> = ({ dataItem, field }) => (
  <td>
    {field && dataItem[field] && (
      <img className={styles.cryptosign} src={secureCloseLogo} alt="Secure Close Logo" />
    )}
  </td>
);

export const useSalesDocuments = () => {
  const params = useParams();
  const appRecId = Number(params.id);

  const orgId = useAuthSelector((s) => s.orgId);
  const compId = useAuthSelector((s) => s.compId);
  const locId = useAuthSelector((s) => s.locId);

  const dispatch = useAppDispatch();
  const { salesDocList, presaleDocList, salesDocLoading, saleData } = useSalesSelector((s) => s);

  const [salesDoc, setSalesDoc] = useState(salesDocList);
  const [presaleDoc, setPresaleDoc] = useState(presaleDocList);
  const [salesDocPrinting, setSalesDocPrinting] = useState<number[]>([]);
  const [allSelect, setAllSelect] = useState(false);
  const [printSelectedLoading, setPrintSelectedLoading] = useState(false);
  const [cryptoSignLoading, setCryptoSignLoading] = useState(false);
  const [docuSignLoading, setDocuSignLoading] = useState(false);
  const [secureCloseLoading, setSecureCloseLoading] = useState(false);
  const [canSendToCryptoSign, setCanSendToCryptoSign] = useState(false);
  const [canSendToDocuSign, setCanSendToDocuSign] = useState(false);
  const [canSendToSecureClose, setCanSendToSecureClose] = useState(false);
  const [secureCloseId, setSecureCloseId] = useState('');
  const [showSecureCloseId, setShowSecureCloseId] = useState(false);
  const [showPostSaleDoc, setShowPostsaleDoc] = useState(
    saleData.sale?.appstatus === AppStatus.Posted
  );
  const [canSwitchDocType, setCanSwitchDocType] = useState(false);
  const [scannedDocModalOpen, setScannedDocModalOpen] = useState(false);
  const [scannedDocsList, setScannedDocsList] = useState<ScannedDocument[]>([]);
  const [scannedDocsLoading, setScannedDocsLoading] = useState(false);
  const [scannedFileDesc, setScannedFileDesc] = useState('');
  const [scannedFile, setScannedFile] = useState<File | null>(null);
  const [uploadingScannedFile, setUploadingScannedFile] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [fileDescriptionError, setFileDescriptionError] = useState(false);

  /** @deprecated move out of component */
  const PrintCell: FC<GridCustomCellProps> = ({ dataItem }) => (
    <td className="k-table-td">
      {salesDocPrinting.includes(dataItem.formRecId) ? (
        <Loader size="medium" />
      ) : (
        <div
          onClick={async () => {
            setPrintSelectedLoading(true);
            setSalesDocPrinting((currPrinting) => [...currPrinting, dataItem.formRecId]);
            await salesService
              .printSalesDoc({
                formIds: [dataItem.formRecId],
                appRecId: appRecId,
              })
              .then((res) => window.open(res))
              .catch(() => toast.error('There was an error printing your documents'))
              .finally(() => {
                setPrintSelectedLoading(false);
                setSalesDocPrinting((currPrinting) =>
                  currPrinting.filter((document) => document !== dataItem.formRecId)
                );
              });
          }}
        >
          <Icons.Print className={styles.printCell} />
        </div>
      )}
    </td>
  );
  /** @deprecated move out of component */
  const ScannedDocPrintButtonCell: FC<GridCustomCellProps> = ({ dataItem }) => {
    const [printing, setPrinting] = useState(false);

    const handlePrint = () => {
      setPrinting(true);
      salesService.printScannedDoc(orgId!, dataItem.fileNameCloud).finally(() => {
        setPrinting(false);
      });
    };

    return (
      <td className="k-table-td">
        {printing ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handlePrint} className={styles.tableActionIconContainer}>
            <Icons.Print className={styles.print} />
          </div>
        )}
      </td>
    );
  };
  /** @deprecated move out of component */
  const DeleteButtonCell: FC<GridCustomCellProps> = ({ dataItem }) => {
    const [deleting, setDeleting] = useState(false);

    const handleDelete = () => {
      setDeleting(true);
      salesService
        .deleteScannedDoc(orgId!, dataItem.recId)
        .then(() => {
          loadScannedDocs();
        })
        .finally(() => {
          setDeleting(false);
        });
    };

    return (
      <td className="k-table-td">
        {deleting ? (
          <Loader size="medium" />
        ) : (
          <div onClick={handleDelete} className={styles.tableActionIconContainer}>
            <Icons.X style={{ color: 'red' }} />
          </div>
        )}
      </td>
    );
  };

  /** @deprecated move out of component */
  const columns: GridColumnProps[] = [
    { field: 'selected' },
    { field: 'saleDocName', title: 'Document to Print' },
    { field: 'isSecureClose', title: 'SecureClose', cells: { data: SecureCloseCell } },
    { field: 'isDocusign', title: 'DocuSign', cells: { data: DocusignCell } },
    { field: 'isCryptoSign', title: 'CryptoSign', cells: { data: CryptoSignCell } },
    { field: 'print', width: 50, cells: { data: PrintCell } },
  ];
  /** @deprecated move out of component */
  const scannedDocumentsColumns: Column[] = [
    { field: 'fileDesc', title: 'Document Description' },
    { field: 'fileUploadedBy', title: 'Uploaded By' },
    { field: 'fileUploadedDT', title: 'Upload Date', cells: { data: DateCell } },
    { field: 'print', title: ' ', cells: { data: ScannedDocPrintButtonCell }, width: 60 },
    { field: 'delete', title: ' ', cells: { data: DeleteButtonCell }, width: 60 },
  ];

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    const rowRecId = event.dataItem.salesdocsRecId;
    const tempSalesDoc = showPostSaleDoc ? [...salesDoc!] : [...presaleDoc!];

    tempSalesDoc.find((c) => c.salesdocsRecId === rowRecId)!.selected = !event.dataItem.selected;

    const cannotSendToCryptoSign = tempSalesDoc.filter(
      (salesDoc) => !salesDoc.isCryptoSign && salesDoc.selected
    );
    const selectedCryptoSign = tempSalesDoc.filter(
      (salesDoc) => salesDoc.isCryptoSign && salesDoc.selected
    );

    const cannotSendToDocuSign = tempSalesDoc.filter(
      (salesDoc) => !salesDoc.isDocusign && salesDoc.selected
    );
    const selectedDocuSign = tempSalesDoc.filter(
      (salesDoc) => salesDoc.isDocusign && salesDoc.selected
    );

    const cannotSendToSecureClose = tempSalesDoc.filter(
      (salesDoc) => !salesDoc.isSecureClose && salesDoc.selected
    );
    const selectedSecureClose = tempSalesDoc.filter(
      (salesDoc) => salesDoc.isSecureClose && salesDoc.selected
    );

    setCanSendToCryptoSign(cannotSendToCryptoSign.length === 0 && selectedCryptoSign.length > 0);
    setCanSendToDocuSign(cannotSendToDocuSign.length === 0 && selectedDocuSign.length > 0);
    setCanSendToSecureClose(cannotSendToSecureClose.length === 0 && selectedSecureClose.length > 0);
    setSalesDoc(tempSalesDoc);
  };

  const onHeaderSelectionChange = (event: GridHeaderSelectionChangeEvent) => {
    const checked = event.nativeEvent.target.checked;
    setAllSelect(checked);

    const tempSalesDoc = [...salesDoc];

    tempSalesDoc.forEach((c) => {
      c.selected = checked;
    });

    setSalesDoc(tempSalesDoc);
  };

  const handlePrintSelected = async () => {
    setPrintSelectedLoading(true);
    const filteredSalesDoc = salesDoc.filter((value) => value.selected);
    const filteredSalesDocFormId = filteredSalesDoc.map((value) => value.formRecId);

    if (filteredSalesDocFormId.length) {
      setSalesDocPrinting(filteredSalesDocFormId);
      await salesService
        .printSalesDoc({
          formIds: filteredSalesDocFormId,
          appRecId: appRecId,
        })
        .then((res) => window.open(res))
        .catch(() => toast.error('There was an error printing your documents'))
        .finally(() => {
          setPrintSelectedLoading(false);
          setSalesDocPrinting([]);
        });
    } else {
      setPrintSelectedLoading(false);
      toast.error('Please select documents to print');
    }
  };

  const handleSendSelectedToCryptoSign = async () => {
    setCryptoSignLoading(true);
    const filteredSalesDoc = salesDoc.filter((value) => value.selected);
    const filteredSalesDocFormId = filteredSalesDoc.map((value) => value.formRecId);

    if (filteredSalesDocFormId.length) {
      await salesService
        .sendSalesDocToCryptoSign({
          formIds: filteredSalesDocFormId,
          appRecId: appRecId,
          cryptoSignDocType: 'Sales',
        })
        .then(() => toast.success('Documents successfully sent to CryptoSign'))
        .catch((e) => toast.error(e.message))
        .finally(() => {
          setCryptoSignLoading(false);
        });
    } else {
      setCryptoSignLoading(false);
      toast.error('Please select documents to send');
    }
  };

  const handleSendSelectedToDocuSign = async () => {
    setDocuSignLoading(true);
    const filteredSalesDoc = salesDoc.filter((value) => value.selected);
    const filteredSalesDocFormId = filteredSalesDoc.map((value) => value.formRecId);
    if (filteredSalesDocFormId.length) {
      await salesService
        .sendSalesDocToDocuSign({
          formIds: filteredSalesDocFormId,
          appRecId: appRecId,
        })
        .then(() => toast.success('Documents successfully sent to DocuSign'))
        .catch((e) => toast.error(e.message))
        .finally(() => {
          setDocuSignLoading(false);
        });
    } else {
      setDocuSignLoading(false);
      toast.error('Please select documents to send');
    }
  };

  const handleSendSelectedToSecureClose = async () => {
    setSecureCloseLoading(true);
    const filteredSalesDoc = salesDoc.filter((value) => value.selected);
    const filteredSalesDocFormId = filteredSalesDoc.map((value) => ({
      formId: value.formRecId,
      secureCloseId: value.secureCloseDocId,
    }));
    if (filteredSalesDocFormId.length) {
      await salesService
        .sendSalesDocToSecureClose({
          appRecId: appRecId,
          secureCloseForms: filteredSalesDocFormId,
        })
        .then((res) => {
          setSecureCloseId(res.presentation_id);
          setShowSecureCloseId(true);
        })
        .catch((e) => toast.error(e.message))
        .finally(() => {
          setSecureCloseLoading(false);
        });
    } else {
      setSecureCloseLoading(false);
      toast.error('Please select documents to send');
    }
  };

  const loadScannedDocs = () => {
    setScannedDocsLoading(true);
    salesService
      .getScannedDocs(orgId!, appRecId)
      .then((res) => setScannedDocsList(res ?? []))
      .finally(() => {
        setScannedDocsLoading(false);
      });
  };

  const uploadScannedFile = () => {
    let hasErrors = false;

    if (!scannedFile) {
      setFileInputError(true);
      hasErrors = true;
    }
    if (!scannedFileDesc) {
      setFileDescriptionError(true);
      hasErrors = true;
    }

    if (hasErrors) return;

    const payload = {
      file: scannedFile!,
      orgId: orgId!,
      compId: compId!,
      locId: locId!,
      appRecId,
      vehRecId: saleData?.sale?.vehRecID ?? 0,
      fileDesc: scannedFileDesc,
    };
    setUploadingScannedFile(true);
    salesService
      .uploadScannedDocument(payload)
      .then(() => {
        loadScannedDocs();
        setScannedFile(null);
        setScannedFileDesc('');
        setScannedDocModalOpen(false);
      })
      .finally(() => setUploadingScannedFile(false));
  };

  useEffect(() => {
    setCanSwitchDocType(saleData.sale?.appstatus === AppStatus.Posted);
    dispatch(getSalesDocList(appRecId)).then((res) => setSalesDoc(res));
    dispatch(getPresaleDocList(appRecId)).then((res) => setPresaleDoc(res));
    loadScannedDocs();
  }, [saleData]);

  return {
    columns,
    scannedDocumentsColumns,
    salesDoc,
    salesDocLoading,
    allSelect,
    onSelectionChange,
    onHeaderSelectionChange,
    handlePrintSelected,
    printSelectedLoading,
    handleSendSelectedToCryptoSign,
    cryptoSignLoading,
    canSendToCryptoSign,
    handleSendSelectedToDocuSign,
    canSendToDocuSign,
    docuSignLoading,
    showSecureCloseId,
    secureCloseId,
    setShowSecureCloseId,
    handleSendSelectedToSecureClose,
    canSendToSecureClose,
    secureCloseLoading,
    showPostSaleDoc,
    setShowPostsaleDoc,
    presaleDoc,
    canSwitchDocType,
    scannedDocsList,
    scannedDocsLoading,
    loadScannedDocs,
    scannedDocModalOpen,
    setScannedDocModalOpen,
    scannedFileDesc,
    setScannedFileDesc,
    scannedFile,
    setScannedFile,
    uploadScannedFile,
    uploadingScannedFile,
    fileInputError,
    setFileInputError,
    fileDescriptionError,
    setFileDescriptionError,
    appRecId,
  };
};
