import { FC } from "react";
import { useNavigate } from "react-router-dom";
// mui
import DetailViewLayout from "@/mui/components/layout/DetailViewLayout";
import DetailViewHeader from "@/mui/components/layout/DetailViewLayout/DetailViewHeader";
import DetailViewBody from "@/mui/components/layout/DetailViewLayout/DetailViewBody";
// components
import PaymentForm from "./components/PaymentForm";
import NavTabs from "./layout/Header/NavTabs";
// state
import { useAccountSelector } from "../../accountSlice";
// interfaces
import { ColTypeCode, colTypeMap } from "@/enums/general";
import { AcctsSubviewKey } from "../../viewInfo";

/** The layout JSX wrapping `<FormLayout />` should be identical to `<AccountsMainPanel />`. */
const PaymentOnlyLayout: FC = () => {
  const navigate = useNavigate();
  const acctInfo = useAccountSelector((s) => s.accountInformation);
  const acctInfoLoading = useAccountSelector((s) => s.accountInformationLoading);
  const colType = useAccountSelector((s) => s.accountInformation?.colType);
  const accountNumber = useAccountSelector((s) => s.accountInformation?.accountNum);

  const colTypeParsed = ColTypeCode.safeParse(colType);
  const paymentTypeLabel = colTypeParsed.success ? colTypeMap[colTypeParsed.data].label : "";
  const paymentTitle = acctInfoLoading
    ? "Loading..."
    : `#${accountNumber} - ${paymentTypeLabel}  Payment for ${acctInfo?.buyer.firstName} ${acctInfo?.buyer.lastName}`;

  // Event handlers
  const handleSuccess = () =>
    navigate(`/accounts/${AcctsSubviewKey.unpaidSales}`, { relative: "path" });

  return (
    <DetailViewLayout backLink={`/accounts/${AcctsSubviewKey.unpaidSales}`}>
      <DetailViewHeader title={paymentTitle}>
        <NavTabs hasTabs={false} />
      </DetailViewHeader>

      <DetailViewBody p="20px">
        <PaymentForm handleSuccess={handleSuccess} />
      </DetailViewBody>
    </DetailViewLayout>
  );
};

export default PaymentOnlyLayout;
