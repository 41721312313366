import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
// state
import { useAuthSelector } from '@/features/auth/authSlice';
import { useAppDispatch } from '@/store/store';
import { salesActions, useSalesSelector } from '@/features/Sales/salesSlice';
import { getAvailableVehicles, getSaleData } from '@/features/Sales/salesActionCreator';
// services
import { applicationService } from '@/services/applicationService';
import { paymentService } from '@/services/paymentService';
import { salesService } from '@/services/salesService';
import { formatDate, formatPhoneNumber, handleRequestError } from '@/utils/helpers/general';
import { buildSalesSam } from './utils';
import { buildCashSalesSam } from './cashSaleUtils';
// utils
import { routeBase } from '@/features/Sales/SalesListView/listViewConfig';
// interfaces
import { EmployeeField } from '@/interfaces/System';
import { Lienholder } from '@/interfaces/Sales';
import { DateFormat } from '@/utils/helpers/general';
import { SaleType } from '@/enums/general';
import { AppStatus, SalesSubviewKey } from '@/features/Sales/enums';

dayjs.extend(isToday);

type FormProps = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  saleDate: string;
  salesperson1: EmployeeField;
  salesperson2: EmployeeField;
  stockNum: string;
  lienHolder: Lienholder;
  milesIn: number;
  miles: number;
  notActual: boolean;
  exceedsLimits: boolean;
  printExempt: boolean;
  verified: boolean;
  verifiedBy?: string;
  verifiedOn: string | null;
  approved: boolean;
  approvedBy?: string;
  approvedOn: string | null;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  customField6: string;
  customField7: string;
  customField8: string;
  customField9: string;
  customField10: string;
  customDate1: string;
  customDate2: string;
  customDate3: string;
  customDate4: string;
  customDate5: string;
  customNum1: number;
  customNum2: number;
  customNum3: number;
  customNum4: number;
  customNum5: number;
  customYN1: boolean;
  customYN2: boolean;
  customYN3: boolean;
  customYN4: boolean;
  customYN5: boolean;
  saleStatus: string;
  leadType: string;
  saleType: SaleType;
  applicationNotes: string;
  weOwe: string;
};

/** @deprecated convert to context */
export const useSaleManagementForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const newSale = params.id === 'newSale';
  const appRecId = Number(params.id);
  const { availableVehicles, saleData, lienholders } = useSalesSelector((s) => s);
  const { orgId, locId, compId, userId, userName } = useAuthSelector((s) => s);
  const buyerRecId = saleData.buyer?.rec_Id;

  const [employeeList, setEmployeeList] = useState<EmployeeField[]>([]);
  const [customFieldLabels, setCustomFieldLabels] = useState([] as string[]);
  const [customDateLabels, setCustomDateLabels] = useState([] as string[]);
  const [customNumLabels, setCustomNumLabels] = useState([] as string[]);
  const [customYNLabels, setCustomYNLabels] = useState([] as string[]);
  const [updateSaleManagementLoading, setUpdateSaleManagementLoading] = useState(false);
  const [saleManagementDataLoading, setSaleManagementDataLoading] = useState(false);
  const [saleManagementHeaderLoading, setSaleManagementHeaderLoading] = useState(false);
  const [appStatusLoading, setAppStatusLoading] = useState(false);

  const vehicle = availableVehicles.find((v) => v.stockNum === saleData.sale?.stocknum);

  const isLease = saleData.sale?.saletype === SaleType.Lease;
  const isFinance = saleData.sale?.saletype === SaleType.Finance;
  const isCash = saleData.sale?.saletype === SaleType.Cash;

  const isPending = saleData.sale?.salestatus === AppStatus.Pending;
  const isPosted = saleData.sale?.salestatus === AppStatus.Posted;
  const isApplicationInactive = saleData.sale?.appstatus === AppStatus.Inactive;
  const isApplicationPending = saleData.sale?.appstatus === AppStatus.Pending;

  const samItems = buildSalesSam(saleData, vehicle);
  const cashSamItems = buildCashSalesSam(saleData, vehicle);

  const showPostLease = !newSale && isLease && isPending;
  const enablePostLease =
    showPostLease && samItems?.length && !samItems.find((item) => item.required);
  const showUnpostLease = isLease && isPosted;

  const showPostFinance = !newSale && isFinance && isPending;
  const enablePostFinance =
    showPostFinance && samItems?.length && !samItems.find((item) => item.required);

  const showUnpostFinance = isFinance && isPosted;

  const showPostCash = !newSale && isCash && isPending;
  const enablePostCash =
    showPostCash && cashSamItems?.length && !cashSamItems.find((item) => item.required);

  const showUnpostCash = isCash && isPosted;

  const showDecline = !newSale && isPending;

  const isDisabled = useMemo(
    () =>
      saleData.sale?.salestatus != undefined &&
      saleData.sale?.salestatus?.toLowerCase() !== 'pending',
    [saleData]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
    setValue,
    watch,
  } = useForm<FormProps>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      saleDate: dayjs().format('YYYY-MM-DD'),
      salesperson1: undefined,
      salesperson2: undefined,
      stockNum: '123456',
      lienHolder: undefined,
      milesIn: undefined,
      miles: undefined,
      notActual: false,
      exceedsLimits: false,
      printExempt: false,
      verified: false,
      verifiedBy: undefined,
      verifiedOn: undefined,
      approved: false,
      approvedBy: undefined,
      approvedOn: '',
      customField1: '',
      customField2: '',
      customField3: '',
      customField4: '',
      customField5: '',
      customField6: '',
      customField7: '',
      customField8: '',
      customField9: '',
      customField10: '',
      customDate1: '',
      customDate2: '',
      customDate3: '',
      customDate4: '',
      customDate5: '',
      customNum1: 0,
      customNum2: 0,
      customNum3: 0,
      customNum4: 0,
      customNum5: 0,
      customYN1: false,
      customYN2: false,
      customYN3: false,
      customYN4: false,
      customYN5: false,
      saleStatus: 'Pending',
      leadType: 'On-Line',
      saleType: SaleType.Lease,
      applicationNotes: '',
      weOwe: 'Nothing Promised - Nothing Owed',
    },
  });

  const submitNewSale = (data: FormProps) => {
    setUpdateSaleManagementLoading(true);
    salesService
      .postNewSale({
        orgId: orgId!,
        locId: locId!,
        compId: compId!,
        firstName: data.firstName,
        lastName: data.lastName,
        saleType: data.saleType,
        leadType: data.leadType,
        email: data.email,
        phoneNumber: data.phoneNumber,
      })
      .then((res) => {
        toast.success('Sale Initiated');
        dispatch(getSaleData(res.data)).then(() => {
          navigate(`/${routeBase}/${SalesSubviewKey.applications}/${res.data}/`);
        });
      })
      .catch(() => toast.error('There was an error posting your sale'))
      .finally(() => setUpdateSaleManagementLoading(false));
  };

  const updateSaleManagement = (data: FormProps) => {
    setUpdateSaleManagementLoading(true);
    salesService
      .updateSaleManagement({
        buyerRecId: buyerRecId!,
        appRecId: appRecId,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        salesPerson1: data.salesperson1?.recId,
        salesPerson2: data.salesperson2?.recId,
        lienHolder: data.lienHolder?.recId,
        notActual: data.notActual,
        printExempt: data.printExempt,
        miles: data.miles,
        exceedsLimits: data.exceedsLimits,
        verified: data.verified,
        verifiedBy: data.verified ? data.verifiedBy : null,
        verifiedOn:
          data.verified && data.verifiedOn && data.verifiedOn !== 'Invalid Date'
            ? data.verifiedOn
            : null,
        approved: data.approved,
        approvedBy: data.approved ? data.approvedBy : null,
        approvedOn:
          data.approved && data.approvedOn && data.approvedOn !== 'Invalid Date'
            ? data.approvedOn
            : null,
        custField1: data.customField1,
        custField2: data.customField2,
        custField3: data.customField3,
        custField4: data.customField4,
        custField5: data.customField5,
        custField6: data.customField6,
        custField7: data.customField7,
        custField8: data.customField8,
        custField9: data.customField9,
        custField10: data.customField10,
        custDate1:
          data.customDate1 && data.customDate1 !== 'Invalid Date' ? data.customDate1 : null,
        custDate2:
          data.customDate2 && data.customDate2 !== 'Invalid Date' ? data.customDate2 : null,
        custDate3:
          data.customDate3 && data.customDate3 !== 'Invalid Date' ? data.customDate3 : null,
        custDate4:
          data.customDate4 && data.customDate4 !== 'Invalid Date' ? data.customDate4 : null,
        custDate5:
          data.customDate5 && data.customDate5 !== 'Invalid Date' ? data.customDate5 : null,
        custNum1: data.customNum1,
        custNum2: data.customNum2,
        custNum3: data.customNum3,
        custNum4: data.customNum4,
        custNum5: data.customNum5,
        custYN1: data.customYN1,
        custYN2: data.customYN2,
        custYN3: data.customYN3,
        custYN4: data.customYN4,
        custYN5: data.customYN5,
        leadType: data.leadType,
        saleType: data.saleType,
        applicationNotes: data.applicationNotes,
        weOwe: data.weOwe,
        vehRecId: saleData.sale?.vehRecID,
      })
      .then((res) => {
        dispatch(salesActions.setSaleData(res));
        dispatch(getAvailableVehicles(compId!));
        toast.success('Sale Management Updated');
      })
      .catch(() => toast.error('There was an error updating sale management'))
      .finally(() => setUpdateSaleManagementLoading(false));
  };

  const getCustomFields = (appRecId: number) => {
    applicationService
      .getCustomFields(appRecId)
      .then((res) => {
        setValue('customField1', res.custField1 ?? '');
        setValue('customField2', res.custField2 ?? '');
        setValue('customField3', res.custField3 ?? '');
        setValue('customField4', res.custField4 ?? '');
        setValue('customField5', res.custField5 ?? '');
        setValue('customField6', res.custField6 ?? '');
        setValue('customField7', res.custField7 ?? '');
        setValue('customField8', res.custField8 ?? '');
        setValue('customField9', res.custField9 ?? '');
        setValue('customField10', res.custField10 ?? '');
        setValue('customDate1', dayjs(res.custDate1).utc().format('YYYY-MM-DD'));
        setValue('customDate2', dayjs(res.custDate2).utc().format('YYYY-MM-DD'));
        setValue('customDate3', dayjs(res.custDate3).utc().format('YYYY-MM-DD'));
        setValue('customDate4', dayjs(res.custDate4).utc().format('YYYY-MM-DD'));
        setValue('customDate5', dayjs(res.custDate5).utc().format('YYYY-MM-DD'));
        setValue('customNum1', Number(res.custNum1));
        setValue('customNum2', Number(res.custNum2));
        setValue('customNum3', Number(res.custNum3));
        setValue('customNum4', Number(res.custNum4));
        setValue('customNum5', Number(res.custNum5));
        setValue('customYN1', res.custYN1 === 'True' ? true : false);
        setValue('customYN2', res.custYN2 === 'True' ? true : false);
        setValue('customYN3', res.custYN3 === 'True' ? true : false);
        setValue('customYN4', res.custYN4 === 'True' ? true : false);
        setValue('customYN5', res.custYN5 === 'True' ? true : false);

        setCustomFieldLabels([]);
        setCustomDateLabels([]);
        setCustomNumLabels([]);
        setCustomYNLabels([]);

        // Not all companies will have all labels - filter out nonexistent ones
        const tempCustomFieldLabels = [];
        const tempCustomDateLabels = [];
        const tempCustomNumLabels = [];
        const tempCustomYNLabels = [];

        for (let i = 1; i <= 10; i++) {
          tempCustomFieldLabels.push((res as any)[`custField${i}L`]);
        }
        setCustomFieldLabels(tempCustomFieldLabels.filter((label) => !!label));

        for (let i = 1; i <= 5; i++) {
          tempCustomDateLabels.push((res as any)[`custDate${i}L`]);
          tempCustomNumLabels.push((res as any)[`custNumL${i}`]);
          tempCustomYNLabels.push((res as any)[`custYNL${i}`]);
        }
        setCustomDateLabels(tempCustomDateLabels.filter((label) => !!label));
        setCustomNumLabels(tempCustomNumLabels.filter((label) => !!label));
        setCustomYNLabels(tempCustomYNLabels.filter((label) => !!label));
      })
      .catch(() => toast.error('There was an error retrieving custom fields'));
  };

  const resetForm = () => {
    reset(
      {
        firstName: saleData.buyer?.b_FIRST,
        lastName: saleData.buyer?.b_LAST,
        phoneNumber: formatPhoneNumber(saleData.buyer?.b_CPHONE?.toString()),
        email: saleData.buyer?.b_EMAIL,
        saleDate: dayjs(saleData.sale?.saledate).utc().format('YYYY-MM-DD'),
        salesperson1: undefined,
        salesperson2: undefined,
        stockNum: saleData.sale?.stocknum,
        milesIn: saleData.milesInMilesOut?.milesIn,
        miles: saleData.milesInMilesOut?.milesOut,
        // These three temp fields do not seem to be the correct ones
        notActual: saleData.sale?.tempNotActual,
        exceedsLimits: saleData.sale?.tempExceedsLim,
        printExempt: saleData.sale?.tempExempt,
        verified: !!saleData.sale?.verified,
        verifiedBy: saleData.sale?.verby || undefined,
        verifiedOn: dayjs(saleData.sale?.veron).utc().format('YYYY-MM-DD'),
        approved: !!saleData.sale?.approved,
        approvedBy: saleData.sale?.approvby || undefined,
        approvedOn: dayjs(saleData.sale?.approvon).utc().format('YYYY-MM-DD'),
        customField1: '',
        customField2: '',
        customField3: '',
        customField4: '',
        customField5: '',
        customField6: '',
        customField7: '',
        customField8: '',
        customField9: '',
        customField10: '',
        customDate1: '',
        customDate2: '',
        customDate3: '',
        customDate4: '',
        customDate5: '',
        customNum1: 0,
        customNum2: 0,
        customNum3: 0,
        customNum4: 0,
        customNum5: 0,
        customYN1: false,
        customYN2: false,
        customYN3: false,
        customYN4: false,
        customYN5: false,
        saleStatus: saleData.sale?.salestatus,
        leadType: saleData.sale?.upType,
        saleType: saleData.sale?.saletype,
        applicationNotes: saleData.sale?.appnotes,
        weOwe: saleData.sale?.weOwe,
      },
      { keepDirty: false }
    );
  };

  const initSaleManagement = useCallback(async () => {
    resetForm();
    if (!newSale) {
      try {
        setSaleManagementDataLoading(true);

        if (appRecId) {
          getCustomFields(appRecId);
        }

        // for some reason setting the lienholder inline doesn't
        // work but queueing the change up outside of the callback does
        setTimeout(() =>
          setValue(
            'lienHolder',
            lienholders.find((lienholder) => lienholder.recId === saleData.sale?.lienholder) ||
              (lienholders[0] as unknown as Lienholder)
          )
        );

        await paymentService
          .getUsersByCompanyId(compId!)
          .then((res) => {
            setEmployeeList(res);
            setValue(
              'salesperson1',
              res.find((employee) => employee.recId === saleData.sale?.salesman) ||
                (undefined as unknown as EmployeeField)
            );
            setValue(
              'salesperson2',
              res.find((employee) => employee.recId === saleData.sale?.salesman2) ||
                (undefined as unknown as EmployeeField)
            );
          })
          .catch(() => toast.error('There was an error receiving the employee list'));
      } finally {
        setSaleManagementDataLoading(false);
      }
    }
  }, [saleData, appRecId]);

  const postSale = () => {
    // right now this same API route is being used for posting leases and finance
    // that may be changing, but if it continues to be the same route, it should be renamed to something more generic
    setSaleManagementHeaderLoading(true);
    salesService
      .postSale({
        appRecId: saleData.sale!.appRecID!,
        saleType: saleData.sale!.saletype!,
      })
      .then((res) => {
        dispatch(salesActions.setSaleData(res));

        toast.success('Sale Posted');
      })
      .catch((e) => handleRequestError(e, 'Unable to post lease'))
      .finally(() => setSaleManagementHeaderLoading(false));
  };

  const unPostSale = () => {
    salesService
      .unPostSale(appRecId)
      .then((res) => {
        toast.success('Sale Unposted');

        dispatch(salesActions.setSaleData(res));
      })
      .catch(() => toast.error('Unable to unpost sale'));
  };

  const declineApplication = () => {
    setSaleManagementHeaderLoading(true);
    salesService
      .declineApplication({
        appRecId: appRecId,
        status: 'Declined',
        dateDeclined: new Date(),
        declinedById: userId!,
        declinedByName: userName!,
      })
      .then(() => {
        dispatch(getSaleData(appRecId));
      })
      .catch(() => toast.error('Unable to decline application'))
      .finally(() => setSaleManagementHeaderLoading(false));
  };

  const activateDeal = useCallback(async () => {
    try {
      setAppStatusLoading(true);
      await salesService.activateDeal(appRecId);
      dispatch(getSaleData(appRecId));
      toast.success('Activated deal');
    } finally {
      setAppStatusLoading(false);
    }
  }, [appRecId]);

  const inactivateDeal = useCallback(async () => {
    try {
      setAppStatusLoading(true);
      await salesService.inactivateDeal(appRecId);
      dispatch(getSaleData(appRecId));
      toast.success('Deal inactivated');
    } finally {
      setAppStatusLoading(false);
    }
  }, [appRecId]);

  useEffect(() => {
    if (!watch('approved')) {
      setValue('approvedBy', undefined);
      setValue('approvedOn', null);
    } else {
      const approvedOn = watch('verifiedOn');
      setValue('approvedBy', watch('approvedBy') || userName!);
      setValue(
        'approvedOn',
        approvedOn && approvedOn !== 'Invalid Date'
          ? approvedOn
          : formatDate(new Date(), { pattern: DateFormat.DateInput })
      );
    }
  }, [watch('approved')]);

  useEffect(() => {
    if (!watch('verified')) {
      setValue('verifiedBy', undefined);
      setValue('verifiedOn', null);
    } else {
      const verifiedOn = watch('verifiedOn');
      setValue('verifiedBy', watch('verifiedBy') || userName!);
      setValue(
        'verifiedOn',
        verifiedOn && verifiedOn !== 'Invalid Date'
          ? verifiedOn
          : formatDate(new Date(), { pattern: DateFormat.DateInput })
      );
    }
  }, [watch('verified')]);

  useEffect(() => {
    initSaleManagement();
  }, [saleData, appRecId]);

  // Need to subscribe to reset function after form is submitted to reset isDirty state - Also recommended in react hook forms docs
  useEffect(() => {
    if (!newSale) {
      resetForm();
    }
  }, [isSubmitSuccessful, newSale]);

  return {
    control,
    customDateLabels,
    customFieldLabels,
    customNumLabels,
    customYNLabels,
    declineApplication,
    employeeList,
    errors,
    handleSubmit,
    isDirty,
    lienholders,
    newSale,
    postSale,
    saleManagementDataLoading,
    saleManagementHeaderLoading,
    submitNewSale,
    unPostSale,
    updateSaleManagement,
    updateSaleManagementLoading,
    watch,
    isPending,
    isPosted,
    showPostLease,
    enablePostLease,
    showUnpostLease,
    showPostFinance,
    enablePostFinance,
    showUnpostFinance,
    isCash,
    showPostCash,
    enablePostCash,
    showUnpostCash,
    showDecline,
    samItems,
    cashSamItems,
    isDisabled,
    isApplicationInactive,
    isApplicationPending,
    appStatusLoading,
    activateDeal,
    inactivateDeal,
  };
};
