import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// components
import SalesListViewLayout from "./SalesListView";
import SalesDetailLayout from "./detailView/SalesDetailLayout";
import WholesaleFormProvider from "./detailView/WholesalesForm/WholesaleFormProvider";
import SaleDetailsContainer from "./components/saleDetails/SaleDetailsContainer";
// state
import SalesViewProvider from "./SalesViewProvider";
import SalesListProvider from "./SalesListView/SalesListProvider";
import SalesDetailProvider from "./detailView/SalesDetailProvider";
import TableProvider from "./SalesListView/SalesListViewTable/TableProvider";
// utils
import { routeBase } from "./SalesListView/listViewConfig";
// interfaces
import { SalesSubviewKey } from "./enums";

/** ### `Sales` view */
const Sales: FC = () => (
  <Routes>
    {/* Detail-view routes */}
    <Route path={`/${SalesSubviewKey.applications}/:id/*`} element={<SaleDetailsContainer />} />
    <Route path={`/${SalesSubviewKey.postedSales}/:id/*`} element={<SaleDetailsContainer />} />

    {/* @todo in a future ticket: migrate above detail-routes to consistent layout set by SalesDetailLayout */}
    <Route path="/:subview/:appRecId/*" element={<SalesDetailLayout />} />

    {/* List-view routes */}
    <Route path="/:subview/" element={<SalesListViewLayout />} />

    {/* Fallback - defaults to Applications */}
    <Route
      path="/"
      element={<Navigate to={`/${routeBase}/${SalesSubviewKey.applications}`} replace={true} />}
    />
    <Route
      path="*"
      // @todo redirect to an error page for the catchall/splat route
      // element={<Navigate to={`/${routeBase}/${SalesSubviewKey.notFound}`} replace={true} />}
      element={<Navigate to={`/${routeBase}/${SalesSubviewKey.applications}`} replace={true} />}
    />
  </Routes>
);

export default () => (
  <SalesViewProvider>
    <SalesListProvider>
      <TableProvider>
        <SalesDetailProvider>
          <WholesaleFormProvider>
            <Sales />
          </WholesaleFormProvider>
        </SalesDetailProvider>
      </TableProvider>
    </SalesListProvider>
  </SalesViewProvider>
);
