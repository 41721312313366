import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
import { TextInput } from "@/components/inputs/text/TextInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const AuthAndNotesSection: FC = () => {
  const employees = usePmtFormCtx((s) => s.employees.value);
  const employee = usePmtFormCtx((s) => s.employee);
  const { form, getFieldProps, setField } = usePmtFormCtx((s) => s.formState);

  return (
    <Grid container direction="column" gap={1.5}>
      <DropdownInput
        label="Employee"
        required
        data={employees || []}
        dataItemKey="recId"
        textField="shortName"
        onChange={(e) => {
          if (typeof e.value === "number") {
            setField("employeeRecId", e.value || null);
          } else if (e.value && e.value?.recId) {
            setField("employeeRecId", e.value.recId || null);
          }
        }}
        value={employee}
        errors={!form.employeeRecId}
      />

      <TextInput label="Password" type="password" required {...getFieldProps("takenByPassword")} />
      <TextInput label="Reference #" {...getFieldProps("paidRef")} />
      <TextInput label="Payment Note" {...getFieldProps("payNote")} />
    </Grid>
  );
};

export default AuthAndNotesSection;
