import { FC } from "react";
// mui
import Grid from "@mui/material/Unstable_Grid2";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { BG_HIGHLIGHT } from "@/mui/theme/colors";
// kendo
import LgLoader from "@/components/loader/LgLoader";

const style: SxProps = {
  p: "20px 32px",
  backgroundColor: BG_HIGHLIGHT,
  borderRadius: "10px",
  gap: "16px",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
} as const;

/** ### @todo move to general scope */
const FormLoader: FC = () => (
  <Grid container direction="column" sx={style}>
    <LgLoader />
  </Grid>
);

export default FormLoader;
