import { TransactionType } from "@/enums/payment";
import { PostPaymentPayload } from "@/services/paymentService";

export const defaultPaymentFormValues: Partial<PostPaymentPayload> = {
  CpiPaid: 0,
  CarPmt: 0,
  DdPmt: 0,
  LcPaid: 0,
  NsfPaid: 0,
  TotalReceived: 0,
};

export const transactionTypeOptions = TransactionType.options.map((type) => ({
  label: type,
  value: type,
}));
