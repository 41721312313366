import { FC } from 'react';
import { Controller } from 'react-hook-form';
// kendo
import {   Button, DropDownButton, Loader, NumberInput, } from "@/components";
import { Spacer } from "@/components/spacer/Spacer";
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
import { RadioButton } from '@progress/kendo-react-inputs';
// components
import { MultipleInputRowRadioButton } from '../../../../multipleInputRow/MultipleInputRowRadioButton';
// state
import { FormProps, useLease } from './useLease';
// style
import styles from './LeaseTab.module.scss';
import tabStyles from '../../../../../components/salesTabs/SalesTab.module.scss';
import { salesService } from '@/services/salesService';
import { useDealsQuotesDetailCtx } from '@/features/Sales/detailView/DealsQuotesDetail/DealsQuotesDetailProvider';
import { DealData } from '@/interfaces/Sales';
import { useNavigate } from 'react-router-dom';
import { SalesSubviewKey } from '@/features/Sales/enums';
import UserInfo from '../../../UserInfo';
import { current } from '@reduxjs/toolkit';

const LeaseTab: FC = () => {
  const {
    control,
    watch,
    termData,
    setValue,
    term1Open,
    setTerm1Open,
    term2Open,
    setTerm2Open,
    term3Open,
    setTerm3Open,
    showFullLeaseQuote,
    setShowFullLeaseQuote,
    customField1Name,
    customField2Name,
    customField3Name,
    customField4Name,
    customField5Name,
    moneyDueAtInceptionField1Name,
    moneyDueAtInceptionField2Name,
    moneyDueAtInceptionField3Name,
    handleSubmit,
    isDirty,
    errors,
    reset,
    setIsLoading,
    isLoading,
    dealData,
    setDealData,
    isQuoteDataLoading,
    isTradeOption50Percent,
    isTradeOption67Percent,
    isTradeOptionNA,
    isQuoteInfoLoading,
  } = useLease();

  const navigate = useNavigate();
  const printTargetRef = useDealsQuotesDetailCtx((s) => s.printTargetRef);

  const onSaveButton = async (currentForm: FormProps) => {
    const updateData: DealData = {
      recId: dealData?.recId,
      quoteDate: currentForm.dateOfQuote,
      customerName: currentForm.fullName,
      customerPhone: currentForm.phoneNumber,

      //Finance properties
      salesPrice: dealData?.salesPrice,
      salesTax: dealData?.salesTax,
      license: dealData?.license,
      title: dealData?.title,
      registration: dealData?.registration,
      serviceContract: currentForm.serviceContract,
      credityInsurance: dealData?.credityInsurance,
      creditDisability: dealData?.creditDisability,
      totalSalesPrice: dealData?.totalSalesPrice,
      tradeAllowance: dealData?.tradeAllowance,
      tradePayoff: dealData?.tradePayoff,
      depositDown: dealData?.depositDown,
      cashOnDelivery: dealData?.cashOnDelivery,
      totalDown: dealData?.totalDown,
      interestRate: dealData?.interestRate,
      amountFinanced: dealData?.amountFinanced,
      schedule: dealData?.schedule,
      firstPaymentDue: dealData?.firstPaymentDue,
      term: dealData?.term,
      paymentAmount: dealData?.paymentAmount,
      numberOfPayments: dealData?.numberOfPayments,
      finalPayment: dealData?.finalPayment,
      finalPaymentDue: dealData?.finalPaymentDue,
      financeCharge: dealData?.financeCharge,
      totalPayments: dealData?.totalPayments,
      customField1Label: dealData?.customField1Label,
      customField1: dealData?.customField1,
      customField2Label: dealData?.customField2Label,
      customField2: dealData?.customField2,
      customField3Label: dealData?.customField3Label,
      customField3: dealData?.customField3,
      customField4Label: dealData?.customField4Label,
      customField4: dealData?.customField4,
      customField5Label: dealData?.customField5Label,
      customField5: dealData?.customField5,
      customField6Label: dealData?.customField6Label,
      customField6: dealData?.customField6,
      specialPaymentNum1: dealData?.specialPaymentNum1,
      specialPaymentNum2: dealData?.specialPaymentNum2,
      specialPaymentNum3: dealData?.specialPaymentNum3,
      specialPaymentNum4: dealData?.specialPaymentNum4,
      specialPaymentAmount1: dealData?.specialPaymentAmount1,
      specialPaymentAmount2: dealData?.specialPaymentAmount2,
      specialPaymentAmount3: dealData?.specialPaymentAmount3,
      specialPaymentAmount4: dealData?.specialPaymentAmount4,
      specialPaymentBeginningDate1: dealData?.specialPaymentBeginningDate1,
      specialPaymentBeginningDate2: dealData?.specialPaymentBeginningDate2,
      specialPaymentBeginningDate3: dealData?.specialPaymentBeginningDate3,
      specialPaymentBeginningDate4: dealData?.specialPaymentBeginningDate4,
      specialPaymentSchedule1: dealData?.specialPaymentSchedule1,
      specialPaymentSchedule2: dealData?.specialPaymentSchedule2,
      specialPaymentSchedule3: dealData?.specialPaymentSchedule3,
      specialPaymentSchedule4: dealData?.specialPaymentSchedule4,

      //Lease Properties
      sellingPrice: currentForm.sellingPrice,
      mmr: currentForm.mmr,
      moneyDueAtInception: currentForm.moneyDueAtInception,
      moneyDueAtInceptionField1: currentForm.moneyDueAtInceptionField1,
      moneyDueAtInceptionField2: currentForm.moneyDueAtInceptionField2,
      moneyDueAtInceptionField3: currentForm.moneyDueAtInceptionField3,
      securityDeposit: currentForm.securityDeposit,
      downPayment: currentForm.downPayment,
      taxRate: currentForm.taxRate,
      apr: currentForm.apr,
      term1: currentForm.term1,
      term1EstPayment: currentForm.term1EstPayment,
      term1ResidualRate: currentForm.term1ResidualRate,
      term1Residual: currentForm.term1Residual,
      term1GuaranteedTradeOption: currentForm.term1GuaranteedTradeOption,
      term1EstimatedNetPayoff: currentForm.term1EstimatedNetPayoff,
      term2: currentForm.term2,
      term2EstPayment: currentForm.term2EstPayment,
      term2ResidualRate: currentForm.term2ResidualRate,
      term2Residual: currentForm.term2Residual,
      term2GuaranteedTradeOption: currentForm.term2GuaranteedTradeOption,
      term2EstimatedNetPayoff: currentForm.term2EstimatedNetPayoff,
      term3: currentForm.term3,
      term3EstPayment: currentForm.term3EstPayment,
      term3ResidualRate: currentForm.term3ResidualRate,
      term3Residual: currentForm.term3Residual,
      term3GuaranteedTradeOption: currentForm.term3GuaranteedTradeOption,
      term3EstimatedNetPayoff: currentForm.term3EstimatedNetPayoff,
      tradeOption: currentForm.tradeOption,
      customField11Label: customField1Name,
      customField11: currentForm.customField11,
      customField12Label: customField2Name,
      customField12: currentForm.customField12,
      customField13Label: customField3Name,
      customField13: currentForm.customField13,
      customField14Label: customField4Name,
      customField14: currentForm.customField14,
      customField15Label: customField5Name,
      customField15: currentForm.customField15,

      daysToFirstPayment: dealData?.daysToFirstPayment,
      afterNumberOfMonths: dealData?.afterNumberOfMonths,
      estimatedApr: dealData?.estimatedApr,
      leaseConversionTerm: dealData?.leaseConversionTerm,
      reFinancePayment: dealData?.reFinancePayment,
      leaseTerm: dealData?.leaseTerm,
      leaseResidualRate: dealData?.leaseResidualRate,
      leaseResidual: dealData?.leaseResidual,
      leaseAddedCapCost: dealData?.leaseAddedCapCost,
      leasePayment: dealData?.leasePayment,
    };

    setIsLoading(true);
    await salesService
  .postDealQuotes(updateData)
  .then((res) => {
    setDealData(res.data);

    if (dealData?.recId !== res.data.recId) {
      const editRoute = `/sales/${SalesSubviewKey.dealsQuotes}/${res.data.recId}/lease`;
      navigate(editRoute);
    }
  })
  .catch((error) => {
    console.error("Error in postDealQuotes:", error); // Log any errors from the request
  })
  .finally(() => {
    setIsLoading(false);
  });
  };

  return (
    <>
      {isQuoteDataLoading || isQuoteInfoLoading ? (
        <div className={tabStyles.body}>
          <div className={tabStyles.loading}>
            <Loader size="large" />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSaveButton)} className={styles.form}>
          <div className={tabStyles.bodyPrintTarget} ref={printTargetRef}>
            <UserInfo control={control} errors={errors} />
            <Spacer size={20} />
            <div className={tabStyles.body} style={{ padding: '41px 40px' }}>
              <div className={tabStyles.bodyContainer} style={{ gap: '100px' }}>
                <div className={tabStyles.bodyChild}>
                  <MultipleInputRowRadioButton label="Trade Option">
                    <Controller
                      name="tradeOption"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="50%"
                            value={0.5}
                            onChange={(e) => onChange(e.value)}
                            defaultChecked={isTradeOption50Percent}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="tradeOption"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="67%"
                            value={1 / 3}
                            onChange={(e) => onChange(e.value)}
                            defaultChecked={isTradeOption67Percent}
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="tradeOption"
                      control={control}
                      render={({ field: { onChange, ...restProps } }) => (
                        <div>
                          <RadioButton
                            {...restProps}
                            label="N/A"
                            value={0}
                            onChange={(e) => onChange(e.value)}
                            defaultChecked={isTradeOptionNA}
                          />
                        </div>
                      )}
                    />
                  </MultipleInputRowRadioButton>
                  <Controller
                    name="sellingPrice"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput rightAlignInput label="Selling Price" {...field} />
                    )}
                  />
                  <Controller
                    name="mmr"
                    control={control}
                    render={({ field }) => <CurrencyInput rightAlignInput label="MMR" {...field} />}
                  />
                  <Controller
                    name="moneyDueAtInception"
                    control={control}
                    render={({ field }) => (
                      <CurrencyInput rightAlignInput label="Money Due at Inception" {...field} />
                    )}
                  />
                  {showFullLeaseQuote && (
                    <>
                      <Controller
                        name="moneyDueAtInceptionField1"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField1Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="moneyDueAtInceptionField2"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField2Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="moneyDueAtInceptionField3"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            label={moneyDueAtInceptionField3Name}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="securityDeposit"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label="Security Deposit" {...field} />
                        )}
                      />
                      <Controller
                        name="downPayment"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            readOnly
                            label="Cap. Cost Reduction"
                            {...field}
                          />
                        )}
                      />
                    </>
                  )}
                  <a
                    className={tabStyles.anchorText}
                    onClick={() => setShowFullLeaseQuote((prev) => !prev)}
                  >
                    {showFullLeaseQuote ? 'Hide' : 'Show'} Full Lease Quote Options
                  </a>
                </div>
                <div className={tabStyles.bodyChild}>
                  {showFullLeaseQuote && (
                    <>
                      <Spacer size={22} />
                      <Controller
                        name="apr"
                        control={control}
                        render={({ field }) => (
                          <NumberInput rightAlignInput format="p2" label="APR" {...field} />
                        )}
                      />
                      <Controller
                        name="customField1"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField1Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField12"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField2Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField13"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField3Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField14"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField4Name} {...field} />
                        )}
                      />
                      <Controller
                        name="customField15"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label={customField5Name} {...field} />
                        )}
                      />
                      <Controller
                        name="serviceContract"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput rightAlignInput label="Service Contract" {...field} />
                        )}
                      />
                      <Controller
                        name="taxRate"
                        control={control}
                        render={({ field }) => (
                          <NumberInput
                            format="p4"
                            rightAlignInput
                            readOnly
                            label="Tax Rate"
                            {...field}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
              <Spacer size={100} />
              <div className={styles.termDataContainer}>
                <div className={styles.termsItemContainer} id="termsItemContainer">
                  <div className={styles.termsItem}>
                    <div className={styles.termsHeader}>
                      <DropDownButton
                        label={
                          <Button
                            buttonStyle={{ fontSize: '16px' }}
                            label={`${watch('term1')} months ↓`}
                            fillMode={'outline'}
                            onClick={() => setTerm1Open((prev) => !prev)}
                          />
                        }
                        onClose={() => setTerm1Open(false)}
                        open={term1Open}
                      >
                        <div className={styles.listContainer}>
                          <ul key={'term1DataList'}>
                            {termData.map((value) => (
                              <li
                                key={`term1Data${value}`}
                                onClick={() => {
                                  setValue('term1', value);
                                  setTerm1Open(false);
                                }}
                              >
                                {value} months
                              </li>
                            ))}
                          </ul>
                        </div>
                      </DropDownButton>
                    </div>
                    <Controller
                      name="moneyDueAtInception"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Money Due At Inception"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term1EstPayment"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Estimated Payment"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term1ResidualRate"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          horizontalLabel={false}
                          label="Residual Rate"
                          rightAlignInput
                          format="p2"
                          max={1}
                          min={0}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term1Residual"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Residual"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    {!!watch('tradeOption') && (
                      <div className={styles.bold}>
                        Guaranteed Trade Option at {watch('term1GuaranteedTradeOption').toFixed(0)}{' '}
                        Months
                      </div>
                    )}
                    {!!watch('tradeOption') && (
                      <Controller
                        name="term1EstimatedNetPayoff"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            horizontalLabel={false}
                            readOnly
                            label="Estimated Net Payoff"
                            {...field}
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className={styles.termsItem}>
                    <div className={styles.termsHeader}>
                      <DropDownButton
                        label={
                          <Button
                            buttonStyle={{ fontSize: '16px' }}
                            label={`${watch('term2')} months ↓`}
                            fillMode={'outline'}
                            onClick={() => setTerm2Open((prev) => !prev)}
                          />
                        }
                        onClose={() => setTerm2Open(false)}
                        open={term2Open}
                      >
                        <div className={styles.listContainer}>
                          <ul key={'term2DataList'}>
                            {termData.map((value) => (
                              <li
                                key={`term2Data${value}`}
                                onClick={() => {
                                  setValue('term2', value);
                                  setTerm2Open(false);
                                }}
                              >
                                {value} months
                              </li>
                            ))}
                          </ul>
                        </div>
                      </DropDownButton>
                    </div>
                    <Controller
                      name="moneyDueAtInception"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Money Due At Inception"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term2EstPayment"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Estimated Payment"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term2ResidualRate"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          rightAlignInput
                          horizontalLabel={false}
                          label="Residual Rate"
                          format="p2"
                          max={1}
                          min={0}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term2Residual"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Residual"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    {!!watch('tradeOption') && (
                      <div className={styles.bold}>
                        Guaranteed Trade Option at {watch('term2GuaranteedTradeOption').toFixed(0)}{' '}
                        Months
                      </div>
                    )}
                    {!!watch('tradeOption') && (
                      <Controller
                        name="term2EstimatedNetPayoff"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            horizontalLabel={false}
                            readOnly
                            label={`Estimated Net Payoff`}
                            {...field}
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className={styles.termsItem}>
                    <div className={styles.termsHeader}>
                      <DropDownButton
                        label={
                          <Button
                            buttonStyle={{ fontSize: '16px' }}
                            label={`${watch('term3')} months ↓`}
                            fillMode={'outline'}
                            onClick={() => setTerm3Open((prev) => !prev)}
                          />
                        }
                        onClose={() => setTerm3Open(false)}
                        open={term3Open}
                      >
                        <div className={styles.listContainer}>
                          <ul key={'term3DataList'}>
                            {termData.map((value) => (
                              <li
                                key={`term3Data${value}`}
                                onClick={() => {
                                  setValue('term3', value);
                                  setTerm3Open(false);
                                }}
                              >
                                {value} months
                              </li>
                            ))}
                          </ul>
                        </div>
                      </DropDownButton>
                    </div>
                    <Controller
                      name="moneyDueAtInception"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Money Due At Inception"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term3EstPayment"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Estimated Payment"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term3ResidualRate"
                      control={control}
                      render={({ field }) => (
                        <NumberInput
                          rightAlignInput
                          horizontalLabel={false}
                          label="Residual Rate"
                          format="p2"
                          max={1}
                          min={0}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="term3Residual"
                      control={control}
                      render={({ field }) => (
                        <CurrencyInput
                          horizontalLabel={false}
                          label="Residual"
                          rightAlignInput
                          readOnly
                          {...field}
                        />
                      )}
                    />
                    {!!watch('tradeOption') && (
                      <div className={styles.bold}>
                        Guaranteed Trade Option at {watch('term3GuaranteedTradeOption').toFixed(0)}{' '}
                        Months
                      </div>
                    )}
                    {!!watch('tradeOption') && (
                      <Controller
                        name="term3EstimatedNetPayoff"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            rightAlignInput
                            horizontalLabel={false}
                            readOnly
                            label="Estimated Net Payoff"
                            {...field}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Spacer size={60} />

              <div className={styles.bottomRow}>
                <Button
                  label="Submit"
                  secondary={!isDirty}
                  disabled={!isDirty}
                  type="submit"
                  fullWidth={false}
                  loading={isLoading}
                />
                <Spacer horizontal={true} size={10} />
                <Button
                  label="Cancel"
                  secondary={!isDirty}
                  disabled={!isDirty}
                  onClick={() => reset()}
                  fullWidth={false}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default LeaseTab;
