import { FC, useEffect } from "react";
import { CircleSpinner } from "react-spinners-kit";
import DOMPurify from "dompurify";
// mui
import Grid from "@mui/material/Unstable_Grid2";
// kendo
import { Modal } from "@/components/modals/Modal";
// utils
import { customerService } from "@/services/customerService";
import useReq from "@/utils/useReq";

const CustomLoader: FC = () => (
  <Grid container direction="row" justifyContent="center" alignItems="start" width="100%">
    <CircleSpinner color="#3299df" size={50} />
  </Grid>
);

const AddrInfo: FC<{ isFrom?: boolean; name: string | null; email: string | null }> = ({
  isFrom = false,
  name,
  email,
}) => (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid width={80} fontWeight={700}>
      {isFrom === true ? "From" : "To"}:
    </Grid>

    <Grid
      container
      direction="row"
      sx={{
        alignItems: "center",
        px: 1,
        py: 2,
        height: 40,
        flex: 1,
        borderRadius: "10px",
        border: "1px solid #c4c4c4",
        fontSize: 12,
        letterSpacing: -0.24,
      }}
    >
      {!!name && <span style={{ marginRight: "12px" }}>{name}</span>}
      <span style={{ color: "#878787" }}>{`<${email}>`}</span>
    </Grid>
  </Grid>
);

const EmailModalLayout: FC<{ emailRecId: number }> = ({ emailRecId }) => {
  const ecomRecord = useReq(async () => {
    return await customerService.getEcomRecordByRecId(emailRecId);
  });

  useEffect(() => {
    ecomRecord.load();
  }, []);

  if (ecomRecord.isLoading) return <CustomLoader />;
  if (ecomRecord.value === null) return <div>Unable to load email contents</div>;

  return (
    <Grid
      container
      direction="column"
      gap={3}
      sx={{ fontSize: 14, letterSpacing: -0.28, color: "#111827" }}
    >
      {/* addressRow */}
      <Grid container direction="row" gap={2}>
        <AddrInfo isFrom name={ecomRecord.value.fromName} email={ecomRecord.value.fromAddress} />
        <AddrInfo name={ecomRecord.value.toName} email={ecomRecord.value.toAddress} />
      </Grid>

      <Grid container direction="column">
        {/* @todo convert to divider */}
        <Grid sx={{ width: "100%", height: 2, backgroundColor: "#c4c4c4" }} />
        <Grid sx={{ fontSize: 12, letterSpacing: -0.24, fontWeight: 700, my: "12px" }}>
          {ecomRecord.value.whatSubject}
        </Grid>
        {/* @todo convert to divider */}
        <Grid sx={{ width: "100%", height: 2, backgroundColor: "#c4c4c4" }} />
      </Grid>

      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ecomRecord.value.body!) }} />
    </Grid>
  );
};

const EmailContentsModal: FC<{ handleClose: () => void; emailRecId: number | null }> = (props) =>
  !!props.emailRecId ? (
    <Modal title="Email" closeButton centerModal {...props} isOpen>
      <EmailModalLayout emailRecId={props.emailRecId} />
    </Modal>
  ) : (
    <></>
  );

export default EmailContentsModal;
