import { AppConfig } from './env/interfaces';

export const devConfig: AppConfig = {
  localUrl: 'https://localhost:5173',
  apiUrl: 'https://localhost:7232', // nocommit
  // apiUrl: 'https://api.qa.dcstest1.com', // nocommit
  openEdgeEnvironment: 'test',
  //signalrUrl: 'https://localhost:7031/sms',
  signalrUrl: 'https://dcl-signalrhub-qa.azurewebsites.net/sms',
  // @todo move to secrets-manager
  googleMapsApiKey: 'AIzaSyDr-UamFrwCy_ROiuvfSfj8pIO8MvlWKc4',
  idsUrl: 'https://auth.qa.dcstest1.com',
  // idsUrl: 'https://auth.afstest2.com',
};
