import { FC } from 'react';
import { Controller } from 'react-hook-form';
// mui
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// kendo
import { CurrencyInput } from "@/components/inputs/currency/CurrencyInput";
// state
import { useWsPmtsViewCtx } from '../../WsPmtsViewProvider';
import { useWsPmtsFormCtx } from '../WsPmtsFormProvider';

/** ###  */
const NsfPaidFieldGroup: FC = () => {
  const paymentData = useWsPmtsViewCtx((s) => s.paymentData);
  const control = useWsPmtsFormCtx((s) => s.wsPmtsForm.control);
  const errors = useWsPmtsFormCtx((s) => s.wsPmtsForm.formState.errors);

  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
      <Grid component="span" sx={{ flex: 1, fontSize: 14, fontWeight: 700 }}>
        NSF Charges
      </Grid>
      <Grid container sx={{ flex: 1.25, gap: "10px", alignItems: "center" }}>
        <Controller
          name="NsfPaid"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CurrencyInput
              label="Paid"
              required
              horizontalLabel={false}
              errors={!!errors.NsfPaid}
              {...field}
            />
          )}
        />
        <CurrencyInput label="Due" readOnly horizontalLabel={false} value={paymentData?.nsfDue} />
      </Grid>
    </Grid>
  );
};

export default NsfPaidFieldGroup;
