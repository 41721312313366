import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
// kendo
import { Grid, GridColumn, GridNoRecords, Loader } from "@progress/kendo-react-all";
import { Button } from "@/components/button/Button";
import { Icons } from "@/components/icons";
import { AccountsMainPanel } from "../accountsMainPanel/AccountsMainPanel";
// state
import { useAccountSelector } from "@/features/Accounts/accountSlice";
// utils
import { paymentService } from "@/services/paymentService";
import { getRouteParamNum } from "@/utils/routing/formatting";
// interfaces
import { type SavedPmtMethodRes } from "@/interfaces/CreditCard";
import { type Column } from "@/components/table/TableInterface";
// style
import styles from "./SavedPaymentMethods.module.scss";

/** ### @deprecated needs refactor */
const SavedPaymentMethods: FC = () => {
  const appRecId = useAccountSelector((s) => getRouteParamNum(s.accountInformation?.appRecId));
  const [savedPayMethods, setSavedPayMethods] =
    useState<(SavedPmtMethodRes & { select: boolean })[]>();
  const [loadingSavedPaymentMethods, setLoadingSavedPaymentMethods] = useState(true);

  const updateSavedPaymentList = () => {
    if (!appRecId) return;
    setLoadingSavedPaymentMethods(true);
    paymentService
      .getSavedPaymentMethods(appRecId)
      .then((res) => {
        const paymentMethods = res.map((pm) => ({ ...pm, select: false }));
        setSavedPayMethods(paymentMethods);
      })
      .finally(() => setLoadingSavedPaymentMethods(false));
  };

  /** 
   * @deprecated use MUI DataGrid 
   * @todo move objects/arrays/fxns out of component body
   */
  const savedPaymentMethodsList: Column[] = [
    { field: "acctType", title: "Type" },
    { field: "custName", title: "Name" },
    { field: "cardProcessor", title: "Processor" },
    { field: "last4", title: "Last 4" },
    {
      field: 'reccuringStatus',
      title: 'Recurring',
      cell: (props) => {
        const isRecurring = props.dataItem.recurringStatus;
        return (
          <td>
            <div className={styles.tdCenter}>
              {isRecurring !== 'Active' ? undefined : <Icons.Checkmark />}
            </div>
          </td>
        );
      },
    },
    {
      field: '',
      title: 'Actions',
      cell: (props) => {
        const { mpdId, recurringStatus } = props.dataItem;
        const [isDeleting, setIsDeleting] = useState(false);
        const handleDelete = () => {
          if (recurringStatus === 'Active') {
            toast.error('Cannot delete payment method used for recurring payments');
            return;
          }
          if (!appRecId) return;
          setIsDeleting(true);
          paymentService
            .deleteSavedPaymentMethods(appRecId, mpdId)
            .then(() => updateSavedPaymentList())
            .finally(() => setIsDeleting(false));
        };
        return (
          <td>
            <div className={styles.tdCenter}>
              <Button
                themeColor="error"
                loading={isDeleting}
                label="Delete"
                fullWidth={false}
                onClick={handleDelete}
              />
            </div>
          </td>
        );
      },
    },
  ];

  useEffect(() => {
    updateSavedPaymentList();
  }, []);

  return (
    <AccountsMainPanel navBarTitle="Saved Payment Methods">
      <div className={styles.container}>
        {/** @deprecated use MUI DataGrid */}
        <Grid
          data={savedPayMethods}
          selectable={{ enabled: true, drag: false, cell: false, mode: 'multiple' }}
        >
          <GridNoRecords>
            {loadingSavedPaymentMethods ? <Loader size="large" /> : 'No payment methods found'}
          </GridNoRecords>
          {savedPaymentMethodsList.map((column, index) => (
            <GridColumn
              key={index}
              field={column.field}
              title={column.title}
              cell={column.cell}
              width={column.width}
              headerSelectionValue={undefined}
            />
          ))}
        </Grid>
      </div>
    </AccountsMainPanel>
  );
};

export default SavedPaymentMethods;
