import { FC } from "react";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";
// utils
import { getAllowedPmtTypes } from "@/utils/helpers/payment";

/** ###  */
const PaidByField: FC = () => {
  const paymentProviderCfg = usePmtFormCtx((s) => s.paymentProviders.value);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("paidBy"));
  const isMiscPmt = usePmtFormCtx((s) => s.formState.config.external.isMiscPmt);
  const totalPayment = usePmtFormCtx((s) => s.formState.form.totalPayment);
  const isNegativeMisc = isMiscPmt && totalPayment < 0;

  const options = getAllowedPmtTypes(paymentProviderCfg);
  const optionsApplied = options.filter((o) => o !== "Credit Card");


  return <DropdownInput label="Payment Type" required data={isNegativeMisc ? optionsApplied : options} {...fieldProps} />;
};

export default PaidByField;
