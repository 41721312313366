import { FC } from "react";
// components
import PaymentFormLayout from "./FormLayout";
// state
import PmtFormProvider from "./PmtFormProvider";

/**
 * @todo determine if `isPrincipalOnly` should be derived from browser params
 *
 * Form state is initialized with data from `getPaymentDetails()`
 * Most of the payment submit payload is contained in the form state
 * Some fields shown in the form are not directly part of the form state - the ones not wrapped in a <Controller />
 * - `totalPayment` which is not part of the submit payload but needs to display;
 * - `employee` which is an object that many of the submit payload fields are populated by;
 *
 * Currently this supports the following payment types:
 * 1. Regular payments ("Take A Payment" in old accounts, collection payments), as indicated by an empty `paymentType`
 * 2. Principal Only, as indicated by `paymentType` === 'PrinOnly'
 */
/** ###  */
const PaymentForm: FC<{ handleSuccess?: () => void; isPrinOnly?: boolean }> = ({
  handleSuccess,
  isPrinOnly = false,
}) => (
  <PmtFormProvider isPrinOnly={isPrinOnly} handleSuccess={handleSuccess}>
    <PaymentFormLayout />
  </PmtFormProvider>
);

export default PaymentForm;
