import { AxiosError } from "axios";
import { useState } from "react";

/** ### Utility hook for managing request state.
 * - This hook abstracts out logic and state frequently used for GET requests.
 *
 * Used for the transition to `react-query`
 *
 * #### Usage:
 * ```tsx
 * // In `VehicleTabProvider.tsx
 *
 * const useCtxState = () => {
 *   // Requests based on a state value
 *   const vehicleCost = useReq(async () => {
 *     if (!invRecId) return;
 *     return await inventoryService.getVehicleCostDetails(invRecId);
 *   });
 *   const vehicleDetails = useReq(async () => {
 *     if (!invRecId) return;
 *     return await inventoryService.getVehicleDetails(invRecId);
 *   });
 *   const colorOptions = useReq(() => inventoryService.getColors());
 *   const bodyStyleOptions = useReq(() => inventoryService.getBodyStyles());
 *   const vehicleTypeOptions = useReq(() => inventoryService.getVehicleTypes());
 *   // ... other states
 *
 *   // Update when browser param changes
 *   useEffect(() => {
 *     colorOptions.load();
 *     bodyStyleOptions.load();
 *     vehicleTypeOptions.load();
 *     // ... other fetches
 *   }, [invRecId]);
 *
 *   // ... other logic
 *
 *   return {
 *     colorOptions,
 *     bodyStyleOptions,
 *     vehicleTypeOptions,
 *     // ... other states
 *   };
 * };
 * ```
 *
 * @todo determine if `load` needs to be memoized
 */
const useReq = <T, TDefault = T | null>(
  req: () => Promise<T | undefined | null | void>,
  isInvalid: boolean = false,
  defaultValue?: TDefault,
  catchCallback?: (e?: AxiosError | Error) => void
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState<TDefault>((defaultValue ?? null) as TDefault);

  const load = async () => {
    if (isInvalid) return;

    try {
      setIsLoading(true);
      const res = await req();
      // const isUnsent = res === undefined;
      setValue((res ?? null) as TDefault);
    } catch (e) {
      catchCallback && catchCallback(e as AxiosError | Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    load,
    value,
    setValue,
  };
};

export default useReq;
