import { z } from "zod";
// validation
import {
  zCondNumberField,
  zDeprecBoolField,
  zDeprecNumberField,
  zDeprecStringField,
} from "@/utils/forms/fieldSchemas";
import { BasePmtFormSchema, ExternalFields } from "./formValidation";
import { ZMpd } from "./paymentSchemas";
import { AccountType, PaymentMethod, PaymentReqType, PmtContext } from "@/enums/payment";

const { achAcctType, waiveConvFee, employeeRecId } = BasePmtFormSchema.shape;

/**
 * @deprecated These fields are redundant - add both a time and a risk cost by maintaining them.
 *
 * These fields either:
 * 1. Already exist in a table;
 * 1. Set to a default value and never modified;
 * 1. Duplicate fields - containing the same values;
 * 1. Calculated by/derived from other fields within the same payload;
 * 1. Are passed to the frontend and never modified before being sent back;
 * 1. Not used on the backend;
 *
 * Remove these properties on the backend:
 * `poNum`, `transactionResult`,
 */
const deprecFields = z.object({
  /** @deprecated Remove. Never used in ANY form. Used in misc payment, see examples: `AcctNum: paymentData?.accountNum ?? "",` - `AcctNum: inPersonPayment.accountNum,``this.acctNum = pmtInfo?.acctNum!; */
  acctNum: z.string().nullable().catch(null), // `
  /** @deprecated Move this integer lookup to the backend */
  achAcctType: achAcctType.transform((val) => (val ? AccountType.options.indexOf(val) : null)),
  /** @deprecated - Remove field and move to backend. This calculation is derived from backend value `paymentData` AND fields [`provider`, `paidBy`, `waiveFee`].  */
  achConvFee: zDeprecNumberField,
  /** @deprecated - Remove. This already comes from the backend */
  billEmail: zDeprecStringField,
  /** @deprecated - Remove field and move to backend. This calculation is derived from backend value `paymentData` AND fields [`provider`, `paidBy`, `waiveFee`].  */
  ccConvFee: zDeprecNumberField,
  /** @deprecated - Remove and calculate on backend. This is derived from fields: [form.paidBy, form.totalReceived, form.totalPayment, form.isPrincipalOnly, form.carPmt]. */
  change: zDeprecNumberField,
  /** @deprecated - Remove and lookup on backend. Referenced from `collections.colType` (table where form data is referenced from). */
  colType: zDeprecStringField,
  /** @deprecated - Remove and lookup on backend. Referenced from `collection.compId`. */
  compId: zDeprecNumberField,
  /** @deprecated - Remove field and move to backend. This calculation is derived from backend value `paymentData` AND fields [`provider`, `paidBy`, `waiveFee`].  */
  convFee: zDeprecNumberField,
  /** @deprecated - Remove field. It derived from `paymentData`. */
  emailB: zDeprecStringField,
  /** @deprecated - Remove field. It derived from `paymentData`. */
  emailC: zDeprecStringField,
  /** @deprecated - Remove field. It derived from `paymentData`. */
  enableAutopay: z.boolean().catch(false),
  /** @deprecated - Remove field. This app is not MyCarPay. It will always be false. */
  isMyCarPay: z.boolean().catch(false),
  /** @deprecated - Remove field. It derived from other field(s): `provider`, `useSavedPaymentMethod`. */
  isNewCard: zDeprecBoolField,
  /** @deprecated - Remove. Never set in ANY form. See on backend: `public bool IsRecurring => Source?.ToLower().Equals("recurring") ?? false;` */
  isRecurring: zDeprecBoolField,
  /** @deprecated - Remove field and calculate on backend. It derived from fields: [`paymentData.lcDue`, `form.lcPaid`, `form.lcWaived`]. */
  lcOwed: z.number().catch(0),
  /** @deprecated - Remove and lookup on backend. This is referenced from `collection.locId`. */
  locId: zDeprecNumberField,
  /** @deprecated - Remove and lookup on backend. This is referenced from `collection.orgId`. */
  orgId: zDeprecNumberField,

  /** @deprecated - Remove. This is calculated on the backend */
  payToFrom: zDeprecStringField,
  /** @deprecated - Remove. This is calculated on the backend */
  pmtContext: PmtContext,
  /** @deprecated - Remove. This is a duplicate field. `paymentMethod`/`paidBy` */
  pmtType: PaymentMethod,
  /** @deprecated - Remove field. It is always `false` */
  processorTestMode: z.boolean().catch(false),
  /** @deprecated - Remove field. It should be calculated from enum on backend. (`provider`) */
  processorType: z.number(),
  /** @deprecated - Remove field. It is always 0. */
  snPmt: z.number().catch(0),
  /** @deprecated - Remove field. It is always ''. */
  source: z.enum(["", "recurring"]).catch(""),
  /** @deprecated - Remove field. It is derived from db */
  stockNum: zDeprecStringField,
  /** @deprecated - Remove field. It is calculated from `user.shortName` - see `getInitials` */
  takenBy: zDeprecStringField,
  /** @deprecated - Remove field. It is derived from in `users.userId` via `userRecId` */
  userEmail: zDeprecStringField,
  /** @deprecated - Remove field. It is always `false`.*/
  usePrintServer: z.boolean().catch(false),
  /** @deprecated - Remove field. It is derived from `users.shortName` via `userRecId` */
  userShortName: zDeprecStringField,
  /** @deprecated - Remove field and move to backend. This field is derived from form field `waiveFee`.  */
  waiveAchConvFee: waiveConvFee,
  /** @deprecated - Remove field and move to backend. This field is derived from form field `waiveFee`.  */
  waiveCcConvFee: waiveConvFee,
});
const renamedFields = z.object({ userRecId: employeeRecId });

export const basePmtReq = BasePmtFormSchema.omit({ employeeRecId: true })
  .merge(renamedFields)
  .merge(deprecFields)
  .merge(ExternalFields.pick({ isPrincipalOnly: true }))
  .extend({
    /** Set via browser param or referenced depending on where form pulls its data from. */
    appRecId: z.number(),
    colRecId: z.number(),
    cpiPaid: zCondNumberField,

    /** Rename to `paymentReqType` on backend. This never set in any form. @note THIS IS NOT `pmtType` */
    paymentType: PaymentReqType.nullable().catch(null),
    /** @deprecated remove field here, add field to Cliq req, and rename to `pmtToken`/`collectJsPmtToken`. Cliq request token. Should only be applied to cliq payments */
    token: ZMpd.shape.token,
  });

export const PmtReq = basePmtReq;
export type PmtReq = z.infer<typeof PmtReq>;
export const PmtReqMisc = PmtReq.omit({
  appRecId: true,
  colRecId: true,
  // emailB: paymentData.buyerEmail,
  emailC: true,
  stockNum: true,
});
export type PmtReqMisc = z.infer<typeof PmtReqMisc>;
