import { FC } from "react";
// kendo
import { DropdownInput } from "@/components/inputs/dropdown/DropdownInput";
// state
import { usePmtFormCtx } from "../PmtFormProvider";

/** ###  */
const ProviderField: FC = () => {
  const enabledProviders = usePmtFormCtx((s) => s.enabledProviders);
  const { setField } = usePmtFormCtx((s) => s.formState);
  const fieldProps = usePmtFormCtx((s) => s.formState.getFieldProps("provider"));

  if (!enabledProviders?.length || enabledProviders.length <= 1) return <></>;

  return (
    <DropdownInput
      label="Processor"
      data={enabledProviders}
      {...fieldProps}
      onChange={(e) => setField("provider", e.value)}
    />
  );
};

export default ProviderField;
