export enum SalesSubviewKey {
  applications = 'applications',
  cashSales = 'cashSales',
  lhPhFunding = 'lhPhFunding',
  outsideFunding = 'outsideFunding',
  postedSales = 'postedSales',
  wholesales = 'wholesales',
  dealsQuotes = 'dealsQuotes',
}

export enum DeskingNavigation {
  QuickQuote = 'Quick Quote',
  DeskingTool = 'Desking Tool',
  LHPHFunding = 'LHPH Funding',
}

export enum BuyerTypeBoth {
  Buyer = 'Buyer',
  CoBuyer = 'Co-Buyer',
  Both = 'Both',
}

export enum JointBuyerType {
  Buyer = 'Buyer',
  CoBuyer = 'Co-Buyer',
  Joint = 'Joint',
}

export enum CreditReportType {
  Credit = 'Credit Report',
  Prescreen = 'Pre-Screen Report',
}

export enum CreditReportBureau {
  Experian = 'Experian',
  Equifax = 'Equifax',
  Transunion = 'Transunion',
}

export enum AppStatus {
  Declined = 'Declined',
  Inactive = 'Inactive',
  Pending = 'Pending',
  Posted = 'Posted',
  Submitted = 'Submitted',
  Wholesale = 'Wholesale',
  Xposted = 'Xposted', // not sure what this one is, but it's in the database
}

export enum YesNoString {
  Yes = 'Yes',
  No = 'No',
}

/** @deprecated this is unsed - keeping here becasue it may be important */
export enum SalesContainerNavigation {
  SaleManagement = 'Sale Management',
  CreditReports = 'Credit Reports',
  Application = 'Application',
  Verification = 'Verification',
  VehicleAndTempTag = 'Vehicle & Temp Tag',
  Terms = 'Terms/Quotes',
  Documents = 'Documents',
}
