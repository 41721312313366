import { FC, PropsWithChildren, createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// services
import { salesService } from "@/services/salesService";
// utils
import useCtxFactory from "@/utils/ctxState/useCtxFactory";
import useReq from "@/utils/useReq";
import { getNumber } from "@/utils/helpers/formatting";

const useCtxState = () => {
  const appRecId = getNumber(useParams().id);

  const downPaymentList = useReq(async () => {
    if (!appRecId) return;

    return await salesService.getDownPaymentList(appRecId);
  });
  const [showDownPaymentForm, setShowDownPaymentForm] = useState(false);

  useEffect(() => {
    downPaymentList.load();
  }, []);

  return {
    // Layout states
    showDownPaymentForm,
    setShowDownPaymentForm,

    // Req-data states
    downPaymentList,
  };
};

type ICtx = ReturnType<typeof useCtxState>;
const Ctx = createContext<ICtx | null>(null);

export type SaleMgmtCtx = ICtx;
export const useSaleMgmtCtx = useCtxFactory(Ctx);
const SaleMgmtProvider: FC<PropsWithChildren> = ({ children }) => (
  <Ctx.Provider value={useCtxState()}>{children}</Ctx.Provider>
);
export default SaleMgmtProvider;
