import { FC, useEffect, useRef } from "react";
import { toast } from "react-toastify";
// mui
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// kendo
import { Modal } from "@/components/modals/Modal";
// utils
import { pollForReceiptDeprec } from "@/utils/helpers/payment";
import { SetState } from "@/interfaces/utilityTypes";

// Event handlers
const handleMessage =
  (paymentLogRecId: number | null, onComplete: () => void) => (e: MessageEvent) => {
    if (!e.origin || !e.origin.includes("repay") || !paymentLogRecId) return;

    if (e.data === "PAYMENT_ERROR") {
      toast.error("There was an error processing your payment");
    }

    if (e.data === "PAYMENT_SUCCESS") {
      pollForReceiptDeprec(paymentLogRecId, onComplete);
    }
  };

/** @deprecated convert to `CommonModal` (MUI implementation) */
const RepayModalDeprec: FC<{
  /** @deprecated access state from `usePmtFormCtx` */
  iframeUrl: string | null | undefined;
  /** @deprecated access state from `usePmtFormCtx` */
  setIframeUrl: SetState<string>;
  /** @deprecated access state from `usePmtFormCtx` */
  paymentLogRecId: number | null;
  onComplete?: () => void;
}> = ({ iframeUrl, setIframeUrl, paymentLogRecId, onComplete = () => null }) => {
  const cbRef = useRef(handleMessage(paymentLogRecId, onComplete));

  useEffect(() => {
    cbRef.current = handleMessage(paymentLogRecId, onComplete);
    const cb = (e: MessageEvent) => cbRef.current(e);

    window.addEventListener("message", cb);

    return () => {
      window.removeEventListener("message", cb);
    };
  }, [iframeUrl, paymentLogRecId]);

  if (!iframeUrl) return <></>;

  return (
    <Modal
      centerModal
      isOpen={!!iframeUrl}
      closeButton
      panelStyle={{ height: "95vh" }}
      panelChildrenStyle={{ overflow: "hidden" }}
      onCloseButtonClick={() => setIframeUrl("")}
    >
      <Grid sx={{ width: "800px", height: "78vh", marginTop: "30px" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={iframeUrl}
          title="Repay Credit Card Payment"
        ></iframe>
      </Grid>
    </Modal>
  );
};

export default RepayModalDeprec;
