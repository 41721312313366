import { SalesSubviewKey } from '@/features/Sales/enums';
import { SubviewInfo } from '../../listViewConfig';
import {
  PostedSalesFilterKey,
  PostedSalesSortKey,
  postedSalesFilterFields,
  postedSalesSortFields,
} from './dataModelConfig';
import { PostedSalesDb } from './interfaces';
import { postedSalesTableColumns } from './tableColumns';

export const postedSalesSubviewConfig: SubviewInfo<PostedSalesSortKey, PostedSalesFilterKey> = {
  text: 'Posted Sales',
  route: SalesSubviewKey.postedSales,
  columns: postedSalesTableColumns,
  dateFilterField: { field: 'saleDate', label: 'Date Sold' },
  initSortField: 'saleDate',
  sortableFields: postedSalesSortFields,
  filterableFields: postedSalesFilterFields,
  dbRowValidationFxn: (row: PostedSalesDb) => PostedSalesDb.new(row),
};
